export const directionMapper = {
  FROM_STORE: 'outgoing',
  FROM_CUSTOMER: 'incoming',
  PRIVATE_NOTE: 'outgoing',
  INTERNAL_MESSAGE: 'internal',
}

export const channelMapper = {
  whatsapp_tyntec: 'whatsapp',
  facebook: 'facebook',
  zoko: 'zoko',
  instagram: 'instagram',
}

export const deliveryStatusMapper = {
  seen: 'read',
  sent: 'delivered',
  failed: 'failed',
  accepted: 'accepted',
  delivered: 'delivered',
}

const templateTypes = ['richTemplate', 'buttonTemplate', 'template']

export const mapConversations = (conversations) =>
  conversations.map(
    ({
      customer_name: name,
      customer_id: customerId,
      customer_image_url: dp,
      unread_count: unreadCount,
      last_message_at: lastMessageAt,
      last_incoming_message_at: lastIncomingMessageAt,
      last_message: lastMessage,
      channel,
      is_pinned: isPinned,
      is_team_chat: isTeam,
      is_unread: isUnread,
      team,
    }) => ({
      name,
      customerId,
      dp,
      unreadCount,
      lastMessageAt,
      lastMessage,
      isPinned,
      channel,
      teamTag: isTeam ? team : '',
      isUnread,
      lastIncomingMessageAt,
    })
  )

export const mapPagination = ({
  current_page_no: currentPageNo,
  page_size: pageSize,
  total_items: totalItems,
  total_pages: totalPages,
  next_page_url: nextPageUrl,
}) => {
  return {
    currentPageNo,
    pageSize,
    totalItems,
    totalPages,
    nextPageUrl,
  }
}

export const mapRealtimeMessage = (chatMessage) => {
  let type = chatMessage.type

  if (chatMessage.direction === 'PRIVATE_NOTE') {
    type = 'privateNote'
  }

  if (chatMessage.direction === 'INTERNAL_MESSAGE') {
    type = 'internalMessage'
  }

  if (templateTypes.includes(type)) {
    type = 'template'
  }

  if (chatMessage?.parent_message?.id) {
    type = 'reply'
  }

  return {
    messageId: chatMessage.id,
    platformMessageId: chatMessage?.platform_message_id,
    timestamp: chatMessage.platform_timestamp,
    type,
    message: chatMessage.text,
    fileCaption: chatMessage.file_caption,
    fileUrl: chatMessage.file_url,
    direction: directionMapper[chatMessage.direction],
    channel: channelMapper[chatMessage?.platform],
    imageUrl: chatMessage.file_url,
    name: chatMessage.agent_name,
    parentId: chatMessage?.parent_message?.id,
    parentMessage: chatMessage?.parent_message,
    subType: chatMessage?.type,
    status: deliveryStatusMapper[chatMessage.deliveryStatus],
    templateData: chatMessage?.template_data
      ? JSON.parse(chatMessage.template_data)
      : null,
    metaData: chatMessage?.metadata,
  }
}

export const mapCustomerMessages = (chatMessages) => {
  return chatMessages.map(
    ({
      message_id: messageId,
      timestamp,
      template_data: templateData,
      direction: direction,
      type,
      type: subType,
      file_caption: fileCaption,
      text: message,
      delivery_status: status,
      channel,
      agent_name: name,
      file_url: fileUrl,
      parent_id: parentId,
      parent_message: parentMessage,
      platform_message_id: platformMessageId,
      metadata: metaData,
    }) => {
      if (direction === 'PRIVATE_NOTE') {
        type = 'privateNote'
      }

      if (direction === 'INTERNAL_MESSAGE') {
        type = 'internalMessage'
      }

      if (parentId) {
        type = 'reply'
      }

      direction = directionMapper[direction]
      channel = channelMapper[channel]
      status = deliveryStatusMapper[status]

      if (templateTypes.includes(type)) {
        type = 'template'
      }

      return {
        messageId,
        timestamp,
        templateData,
        type,
        name,
        fileCaption,
        message,
        direction,
        channel,
        fileUrl,
        status,
        parentId,
        parentMessage,
        subType,
        platformMessageId,
        metaData,
      }
    }
  )
}

export const selectedConversationMapper = (selectedConversation) => {
  return {
    customerId: selectedConversation.id,
    name: selectedConversation.name,
    channel: selectedConversation.channel,
    image: selectedConversation.image,
    phone: selectedConversation.phone,
    tags: selectedConversation.tags,
    assignedTo: selectedConversation.assigned_to,
    isTeam: selectedConversation.is_team,
    isPinned: selectedConversation.is_pinned,
    isUnread: selectedConversation.is_unread,
    isUnresponded: selectedConversation.is_unresponded,
    lastIncomingMessageAt: selectedConversation.last_incoming_message_at,
    lastMessage: selectedConversation.last_message,
    lastMessageAt: selectedConversation.last_message_at,
    lastOutgoingMessageAt: selectedConversation.last_outgoing_message_at,
    unreadCount: selectedConversation.unread_count,
  }
}
