export const isEmpty = (obj) => {
  return Object.keys(obj)?.length === 0
}

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj))

export const asValueArray = (obj) => Object.values(obj)

export const dynamicSort = ({ data = [], sortField, sortDir = 'asc' }) =>
  data?.sort((a, b) => {
    const comparison =
      a[sortField] > b[sortField] ? 1 : b[sortField] > a[sortField] ? -1 : 0
    return sortDir === 'desc' ? comparison * -1 : comparison
  })
