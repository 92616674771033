import localforage from 'localforage'

import config from 'lib/config'
import { settingsRoutes, shopifyAppGrantRoutes } from 'lib/config/routes'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import { deepClone } from 'lib/utils/object'
import { getParams } from 'lib/utils/url'

import { INTEGRATIONTYPES } from '../constants'
import {
  connectionStatusMapper,
  generateReqPayload,
  cleanUpShopifyShopURL,
} from './helpers'
import initialState from './initialState'

const actions = {
  getShopifyPaymentStatus:
    ({ headers, history }) =>
    async ({ setState, getState, dispatch }) => {
      setState({
        shopify: {
          ...getState().shopify,
          paymentUrlLoader: true,
          paymentUrl: '',
          showRetry: false,
        },
      })
      try {
        const resp = await fetcher(
          `${config.BASE_API}/integrations/shopify/payment/status`,
          {
            headers,
            params: {
              shop: getParams('shop'),
            },
          }
        )
        if (resp?.data) {
          window.console.log('PAYMENT URL', resp?.data)
          setState({
            shopify: {
              ...getState().shopify,
              paymentUrl: resp?.data?.payment_url,
              showRetry:
                resp?.data?.status === 'pending' && !resp?.data?.payment_url,
            },
          })
          if (resp?.data?.status === 'active') {
            window.globals.service.toast('Shopify installation completed.', {
              type: 'success',
            })
            const integrationsUrl = `${window.location.origin}${settingsRoutes.INTEGRATIONS}`
            const url = `${
              shopifyAppGrantRoutes.SHOPIFY_GRANT_INSTALL_COMPLETE
            }?redirect_uri=${window.encodeURIComponent(
              integrationsUrl
            )}&shop=${getParams('shop')}`
            window.console.log('REDIRECT URL', url)
            history.push(url, {
              redirectUri: window.encodeURIComponent(integrationsUrl),
            })
            dispatch(actions.checkConnectionStatus({ headers }))
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'getShopifyPaymentStatus')
        window?.globals?.service?.toast('Failed to load data', {
          type: 'error',
        })
      } finally {
        setState({
          shopify: {
            ...getState().shopify,
            paymentUrlLoader: false,
          },
        })
      }
    },
  redirectToShopifyInstall:
    ({ headers }) =>
    async ({ getState, setState }) => {
      const { shopify } = getState()
      setState({
        shopify: {
          ...shopify,
          installLoader: true,
        },
      })
      const resp = await fetcher(
        `${config.BASE_API}/integrations/shopify/private/install`,
        {
          headers,
          params: {
            shop: cleanUpShopifyShopURL(shopify.guestDomain) + '.myshopify.com',
          },
        }
      )
      window.location.href = resp.data.redirect_url
    },
  setShopifyGuestDomain:
    (guestDomain) =>
    ({ getState, setState }) =>
      setState({
        shopify: {
          ...getState()?.shopify,
          guestDomain,
        },
      }),
  getShopifyHost:
    ({ headers, shop }) =>
    async ({ getState, setState }) => {
      setState({
        shopify: {
          ...getState().shopify,
          hostLoader: true,
        },
      })
      try {
        const resp = await fetcher(
          `${config.BASE_API}/integrations/ecom-settings`,
          {
            headers,
            params: {
              shop,
            },
          }
        )
        if (resp?.data) {
          const store_name = resp?.data?.store_name
          const host = btoa(`${store_name}/admin`)
          setState({
            shopify: {
              ...getState().shopify,
              host,
            },
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'getHost')
        window?.globals?.service?.toast('Failed to get host information', {
          type: 'error',
        })
      } finally {
        setState({
          shopify: {
            ...getState().shopify,
            hostLoader: false,
          },
        })
      }
    },
  getNativeNonce:
    () =>
    async ({ getState, setState }) => {
      const nativeNonce = await localforage.getItem('shopify-native-nonce')
      setState({
        shopify: {
          ...getState().shopify,
          nativeNonce,
        },
      })
    },
  connectShopifyStore:
    ({ id: storeId, shop, headers, history }) =>
    async ({ setState, getState }) => {
      setState({
        shopify: {
          ...getState().shopify,
          connectStoreLoader: true,
        },
      })
      try {
        const resp = await fetcher(
          `${config.BASE_API}/integrations/shopify/connectstore`,
          {
            headers,
            body: {
              store_id: storeId,
              shop,
            },
          }
        )
        if (resp?.data?.success) {
          history.push(
            `${shopifyAppGrantRoutes.SHOPIFY_GRANT_PAYMENTS}?shop=${shop}`
          )
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'connectShopifyStore')
        window?.globals?.service?.toast('Failed to connect store', {
          type: 'error',
        })
      } finally {
        setState({
          shopify: {
            ...getState().shopify,
            connectStoreLoader: false,
            guestDomain: shop,
          },
        })
      }
    },
  continueWithFacebook:
    (type) =>
    async ({ setState, getState }) => {
      const resp = await new Promise((resolve) => {
        window.FB.api(`${config.FACEBOOK_BASE_URL}/me`, (resp) => {
          resolve(resp)
        })
      })
      setState({
        [type]: {
          ...getState()?.[type],
          userName: resp?.name,
        },
      })
    },
  getListOfPages:
    (accessToken, type) =>
    async ({ setState, getState }) => {
      const resp = await new Promise((resolve) => {
        window.FB.api(
          `${config.FACEBOOK_BASE_URL}/me/accounts`,
          {
            access_token: accessToken,
            fields: config.FACEBOOK_PAGE_FIELDS,
          },
          (resp) => {
            resolve(resp)
          }
        )
      })
      const isFacebook = type === INTEGRATIONTYPES.FACEBOOK
      const _pagesList = isFacebook
        ? resp?.data
        : resp?.data?.filter((item) => item?.instagram_business_account?.id)

      const selectedPageId = _pagesList?.length === 1 ? _pagesList?.[0]?.id : ''
      setState({
        [type]: {
          ...getState()?.[type],
          pagesList: _pagesList?.map((item) => {
            return {
              ...item,
              label: isFacebook
                ? item?.name
                : item?.instagram_business_account?.name ||
                  item?.instagram_business_account?.username,
              value: item?.id,
              image: isFacebook
                ? item?.picture?.data?.url
                : item?.instagram_business_account?.profile_picture_url,
            }
          }),
          selectedPageId,
        },
      })
    },
  setPageToConnect:
    (selectedPageId, type) =>
    ({ setState, getState }) => {
      setState({
        [type]: {
          ...getState()?.[type],
          selectedPageId,
        },
      })
    },
  connectWithMessenger:
    ({ headers, type }) =>
    async ({ getState, setState, dispatch }) => {
      setState({
        [type]: {
          ...getState()?.[type],
          loader: true,
        },
      })
      const { selectedPageId, pagesList } = getState()?.[type]
      const selectedPage = pagesList?.find(({ id }) => id === selectedPageId)
      const isFacebook = type === INTEGRATIONTYPES.FACEBOOK
      try {
        const resp = await fetcher(
          `${config.BASE_API}/integrations?platform=${type}`,
          {
            headers,
            body: generateReqPayload({
              selectedPage,
              isFacebook,
            }),
            method: 'POST',
          }
        )
        if (resp?.data && !resp?.data?.error) {
          await dispatch(actions.checkConnectionStatus({ headers }))
          window.globals?.service?.toast(
            `integrations.toast.${
              isFacebook ? `facebookConnectSuccess` : `instagramConnectSuccess`
            }`,
            {
              type: 'success',
            }
          )
        } else if (resp?.data?.description) {
          window.globals?.service?.toast(resp?.data?.description, {
            type: 'error',
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'connectWithFacebook')
        window.globals?.service?.toast(
          `integrations.toast.${
            isFacebook ? `facebookConnectFail` : `instagramConnectFail`
          }`,
          {
            type: 'error',
          }
        )
      } finally {
        setState({
          [type]: {
            ...getState()?.[type],
            loader: false,
          },
        })
      }
    },
  checkConnectionStatus:
    ({ headers }) =>
    async ({ getState, setState }) => {
      setState({
        pageLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/integrations`, {
          headers,
        })
        if (!resp?.data?.error && resp?.data) {
          const { facebook, instagram, shopify, whatsapp } = getState()
          const {
            facebook: _facebook,
            instagram: _instagram,
            shopify: _shopify,
            whatsapp: _whatsapp,
          } = resp?.data
          setState({
            facebook: {
              ...facebook,
              ...connectionStatusMapper(_facebook),
            },
            instagram: {
              ...instagram,
              ...connectionStatusMapper(_instagram),
            },
            shopify: {
              ...shopify,
              ...connectionStatusMapper(_shopify),
            },
            whatsapp: {
              ...whatsapp,
              ...connectionStatusMapper(_whatsapp),
            },
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'checkFacebookConnectionStatus')
      } finally {
        setState({
          pageLoader: false,
        })
      }
    },
  disconnectMessenger:
    ({ headers, type }) =>
    async ({ setState }) => {
      window?.FB?.getLoginStatus((res) => {
        if (res.status === 'connected') {
          window?.FB?.logout((resp) =>
            window.console.log('fb logout res', resp)
          )
        }
      })
      const isFacebook = type === INTEGRATIONTYPES.FACEBOOK
      try {
        const resp = await fetcher(
          `${config.BASE_API}/integrations?platform=${type}`,
          {
            headers,
            method: 'DELETE',
          }
        )
        if (resp?.data && !resp?.data?.error) {
          setState({
            [type]: deepClone(initialState[type]),
          })
          window.globals?.service?.toast(
            `integrations.toast.${
              isFacebook
                ? `facebookDisconnectSuccess`
                : `instagramDisconnectSuccess`
            }`,
            {
              type: 'success',
            }
          )
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'disconnectFacebook')
        window.globals?.service?.toast(
          `integrations.toast.${
            isFacebook ? `facebookDisconnectFail` : `instagramDisconnectFail`
          }`,
          {
            type: 'error',
          }
        )
      }
    },
  resetIntegrations:
    (key = '') =>
    ({ setState }) => {
      if (key) {
        setState({
          [key]: deepClone(initialState[key]),
        })
      } else {
        setState({
          facebook: deepClone(initialState.facebook),
          instagram: deepClone(initialState.instagram),
          shopify: deepClone(initialState.shopify),
          whatsapp: deepClone(initialState.whatsapp),
        })
      }
    },
}
export default actions
