import React from 'react'

import { Icon, icons } from 'common/UI/Icon'
import { Text } from 'common/UI/Text'
import { logout } from 'lib/firebase'

import styles from './NoStoreFound.module.scss'

const NoStoreFound = () => {
  return (
    <div className={styles.noStoreFoundWrapper}>
      <div className={styles.innerWrapper}>
        <Icon
          source={icons.productListEmpty}
          size="var(--p176)"
          className={styles.noStateImg}
        />
        <Text
          translate
          fontSize="xl"
          fontWeight={500}
          className={styles.message}
        >
          noStoreFound.message
        </Text>
        <Text
          translate
          fontSize="sm"
          fontWeight={400}
          className={styles.description}
        >
          noStoreFound.description
        </Text>
        <Text
          translate
          fontSize="sm"
          fontWeight={500}
          className={styles.link}
          onClick={logout}
        >
          noStoreFound.buttonText
        </Text>
      </div>
    </div>
  )
}

export default NoStoreFound
