import { Button, TextStyle } from '@shopify/polaris'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Loading } from 'common/UI/Loading'
import { integrationsApps } from 'lib/config/routes'
import i18n from 'lib/i18n'
import { useAuthHeaders } from 'modules/Auth/store'
import {
  useShopifyHost,
  useShopifyPaymentURL,
} from 'modules/Settings/pages/Integrations/store'

import ShopifyRedirection from '../ShopifyRedirection'
import styles from './ShopifyPayments.module.scss'

const ShopifyPayments = () => {
  const [headers] = useAuthHeaders()
  const history = useHistory()
  const [, actions] = useShopifyHost()
  const [{ paymentUrlLoader, paymentUrl, showRetry }] = useShopifyPaymentURL()

  useEffect(() => {
    actions.getShopifyPaymentStatus({ headers, history })
  }, [actions, headers, history])

  if (paymentUrlLoader) {
    return (
      <div className={styles.shopifyPaymentsWrapper}>
        <Loading />
      </div>
    )
  }

  if (paymentUrl) {
    return (
      <div className={styles.shopifyPaymentsWrapper}>
        <ShopifyRedirection
          title={i18n.t('shopify.payment.title')}
          description={i18n.t('shopify.payment.desc')}
          redirectURL={paymentUrl}
          externalLink
        />
      </div>
    )
  }

  if (showRetry)
    return (
      <div className={styles.shopifyPaymentsWrapper}>
        <TextStyle variation="subdued">
          Setup failed. Please{' '}
          <Button
            onClick={() => {
              actions.getShopifyPaymentStatus({ headers, history })
            }}
          >
            Retry
          </Button>
        </TextStyle>
      </div>
    )

  return (
    <div className={styles.shopifyPaymentsWrapper}>
      <TextStyle variation="subdued">
        Something went wrong{' '}
        <Link to={integrationsApps.SHOPIFY_APP}>Go to Integrations</Link>
      </TextStyle>
    </div>
  )
}

export default ShopifyPayments
