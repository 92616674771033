import { formatDate } from 'lib/dates'
import { uniqBy } from 'lib/utils/array'
import { RealtimeEvents } from 'modules/Socket/realtimeEvents'

import { dateSortOptions } from '../../initialState'
import { generateSentimentEmoji } from '../../mapper'

const handleMessageEvents = ({ payload, eventName, stateObject }) => {
  const channelMapper = {
    whatsapp_tyntec: 'whatsapp',
    facebook: 'facebook',
    instagram: 'instagram',
  }

  const updatedState = {} // This will hold the final updated state

  // postActions is an object returned with configs for further actions
  const postActions = {
    playNotification: false, // The final state of this variable determines whether to play notification
  }

  const { setState, getState } = stateObject
  const { queueList, dateSort, queuePagination } = getState()
  const { totalItems, currentPageNo, totalPages } = queuePagination
  const { assignment, customer, message } = payload

  // Move the original queueList to updatedQList to avoid mutating the original array
  const updatedQList = [...queueList]

  if (assignment.id === '') {
    // Have to continue only if assignment is empty

    const itemIdx = updatedQList.findIndex((t) => t.customerId === customer.id) // [itemIdx > -1] if the customer/item already exists in the queue
    if (itemIdx > -1) {
      // Item already exists in the queue => update item values

      updatedQList[itemIdx] = {
        ...updatedQList[itemIdx],
        sentiment: generateSentimentEmoji(customer.sentiment),
        lastMessage:
          message.direction === 'INTERNAL_MESSAGE'
            ? message.text +
              ' on ' +
              formatDate(message.platform_timestamp, '', 'YYYY-MM-DD HH:mm')
            : message.text,
        lastMessageAt: message.platform_timestamp,
        channel: channelMapper?.[message.platform],
      }

      if (eventName === RealtimeEvents.messageIn) {
        updatedQList[itemIdx].lastIncomingMessageAt = message.platform_timestamp
      }

      updatedState.queueList = uniqBy(
        updatedQList,
        ({ customerId }) => customerId
      )
    } else if (eventName === RealtimeEvents.messageIn) {
      // Item doesn't exist in the queue => Add item to queue

      postActions.playNotification = true

      const selectedIdx = updatedQList.findIndex((conv) => conv.selected) // [selectedIdx > -1] if atleast one item is selected in the queue
      // Create item
      const item = {
        name: customer.name,
        selected: false,
        sentiment: generateSentimentEmoji(customer.sentiment),
        customerId: customer.id,
        platformId: customer.id_on_platform,
        lastMessage: message.text,
        channel: channelMapper?.[message.platform],
        lastMessageAt: message.platform_timestamp,
        isPendingToShow: selectedIdx > -1, // Mark pending to show if at least one item is selected in the queue
        lastIncomingMessageAt: message.platform_timestamp,
      }

      if (dateSort === dateSortOptions.desc) {
        // if sort is descending, add item to the top of the queue
        updatedQList.unshift(item)
      } else if (currentPageNo === totalPages) {
        // If sort is ascending,
        // add item to bottom of the queue list if the current page is the last page
        updatedQList.push(item)
      }
      updatedState.queueList = uniqBy(
        updatedQList,
        ({ customerId }) => customerId
      )

      // Update total items count
      updatedState.queuePagination = {
        ...queuePagination,
        totalItems: Number(totalItems) + 1,
      }
    }
  }

  setState(updatedState)
  return postActions
}

export default handleMessageEvents
