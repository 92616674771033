import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './ChatScreenSkeleton.module.scss'
export const ChatScreenSkeleton = () => {
  return (
    <>
      <_Desktop />
      <_Mobile />
    </>
  )
}

const _Desktop = () => {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox="0 0 1440 820"
      className={`${styles.wrapper} hidden-xs`}
      preserveAspectRatio="none"
    >
      {/* HEADER START*/}
      <rect x="15" y="15" rx="6" ry="6" width="110" height="25" />
      <rect x="413" y="15" rx="6" ry="6" width="110" height="25" />
      <rect x="543" y="15" rx="6" ry="6" width="110" height="25" />
      <rect x="673" y="15" rx="6" ry="6" width="110" height="25" />
      <rect x="1260" y="15" rx="6" ry="6" width="160" height="25" />
      <rect x="1050" y="15" rx="6" ry="6" width="180" height="25" />
      <rect x="0" y="50" rx="6" ry="6" width="1440" height="1" />
      {/* HEADER END*/}
      {/* CHAT LIST HEADER START*/}
      <rect x="15" y="60" rx="6" ry="6" width="80" height="10" />
      <rect x="15" y="80" rx="6" ry="6" width="200" height="10" />
      <rect x="15" y="110" rx="6" ry="6" width="150" height="30" />
      <rect x="185" y="110" rx="6" ry="6" width="30" height="30" />
      {/* CHAT LIST HEADER END*/}
      {/* CHAT LIST START*/}
      <rect x="15" y="160" rx="6" ry="6" width="60" height="30" />
      <rect x="85" y="160" rx="6" ry="6" width="60" height="30" />
      <rect x="155" y="160" rx="6" ry="6" width="60" height="30" />
      <rect x="0" y="200" rx="6" ry="6" width="230" height="1" />

      {[...Array(11)].map((_, i) => {
        const ystart = 230 + i * 60
        const cy = ystart
        const y1 = cy - 15
        const y2 = cy

        return (
          <React.Fragment key={i}>
            <circle cx="35" cy={cy} r="20" />
            <rect x="60" y={y1} rx="4" ry="4" width="150" height="8" />
            <rect x="60" y={y2} rx="4" ry="4" width="130" height="10" />
          </React.Fragment>
        )
      })}
      {/* CHAT LIST END*/}
      <rect x="230" y="50" rx="6" ry="6" width="1" height="770" />
      {/* CHAT WINDOW START*/}
      <rect x="250" y="70" rx="2" ry="2" width="1170" height="680" />
      <rect x="250" y="760" rx="2" ry="2" width="1120" height="40" />
      <circle cx="1400" cy="779" r="20" />
      {/* CHAT WINDOW END*/}
    </ContentLoader>
  )
}

const _Mobile = () => {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox="0 0 380 820"
      className={`${styles.wrapper} hidden-xl`}
      preserveAspectRatio="none"
    >
      {/* HEADER START*/}
      <rect x="15" y="15" rx="6" ry="6" width="35" height="25" />
      <rect x="140" y="15" rx="6" ry="6" width="100" height="25" />
      <rect x="0" y="50" rx="6" ry="6" width="380" height="1" />
      {/* HEADER END*/}
      {/* CHAT LIST HEADER START*/}
      <rect x="15" y="70" rx="6" ry="6" width="80" height="10" />
      <rect x="15" y="90" rx="6" ry="6" width="200" height="10" />
      <rect x="15" y="120" rx="6" ry="6" width="350" height="30" />
      {/* CHAT LIST HEADER END*/}
      {/* CHAT LIST START*/}
      <rect x="15" y="170" rx="6" ry="6" width="70" height="30" />
      <rect x="95" y="170" rx="6" ry="6" width="70" height="30" />
      <rect x="175" y="170" rx="6" ry="6" width="70" height="30" />
      <rect x="0" y="210" rx="6" ry="6" width="380" height="1" />

      {[...Array(15)].map((_, i) => {
        const ystart = 240 + i * 60
        const cy = ystart
        const y1 = cy - 15
        const y2 = cy + 5

        return (
          <React.Fragment key={i}>
            <circle cx="35" cy={cy} r="20" />
            <rect x="70" y={y1} rx="4" ry="4" width="290" height="8" />
            <rect x="70" y={y2} rx="4" ry="4" width="200" height="10" />
          </React.Fragment>
        )
      })}
      {/* CHAT LIST END*/}
    </ContentLoader>
  )
}
