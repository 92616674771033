export const uniqBy = (array, iterator = ({ id }) => id) => {
  const duplicates = []
  return array.filter((arr) => {
    const id = iterator(arr)
    if (!duplicates.includes(id)) {
      duplicates.push(id)
      return true
    } else {
      return false
    }
  })
}

export const modifyInArrRange = ({
  arr,
  marker1,
  marker2,
  markerSelector = 'id',
  modifier,
}) => {
  /*
    Call the "modifier" function for all items in the array
    between the markers (marker1 and marker2) and return the modified array.

    Mostly used to mock "shift-selection"
  */

  const marker1Idx = arr.findIndex((item) => item[markerSelector] === marker1)
  const marker2Idx = arr.findIndex((item) => item[markerSelector] === marker2)

  const minIdx = Math.max(Math.min(marker2Idx, marker1Idx), 0)
  const maxIdx = Math.max(marker2Idx, marker1Idx)

  for (let idx = minIdx; idx <= maxIdx; idx++) {
    modifier(arr[idx])
  }

  return arr
}
