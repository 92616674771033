import PropTypes from 'prop-types'
import React from 'react'

import { Icon, icons } from '../Icon'
import styles from './Empty.module.scss'

export const Empty = (props) => {
  const { children = 'No results', iconSize = 'var(--p128)' } = props
  return (
    <div className={styles.emptyBase}>
      <Icon
        className={styles.icon}
        source={icons.productListEmpty}
        size={iconSize}
      />
      {children}
    </div>
  )
}

Empty.propTypes = {
  children: PropTypes.node,
  iconSize: PropTypes.string,
}
