import { useEffect } from 'react'

import { useStoreList } from 'modules/Auth/store'

const LogData = () => {
  const [{ storeList, currentStoreId }] = useStoreList()
  useEffect(() => {
    const store = storeList.find(({ id }) => id === currentStoreId)
    if (store && window?.globals) {
      window.globals._logData = {
        ...window?.globals?._logData,
        store_id: store?.id,
        store_name: store?.name,
      }
    }
  }, [storeList, currentStoreId])
  return null
}

export default LogData
