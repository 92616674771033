import { Provider } from '@shopify/app-bridge-react'
import { AppProvider, Card, Layout, Page } from '@shopify/polaris'
import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'

import { Loading } from 'common/UI/Loading'
import config from 'lib/config'
import { shopifyAppGrantRoutes } from 'lib/config/routes'
import { getParams, inIframe } from 'lib/utils/url'

import styles from './ShopifyGrant.module.scss'
import ShopifyGrantPricing from './ShopifyGrantPricing'
import ShopifyInstallComplete from './ShopifyInstallComplete'
import ShopifyPayments from './ShopifyPayments'
import ShopifyStoreMatcher from './ShopifyStoreMatcher'

const ShopifyGrant = ({ children }) => {
  const _config = {
    apiKey: config.SHOPIFY_API_KEY,
    host: window?.btoa(getParams('shop') + '/admin'),
  }
  window.console.log('_config', _config, location)

  const content = (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={shopifyAppGrantRoutes.SHOPIFY_GRANT_PRICING}>
          <ShopifyGrantPricing />
          {/* merchant not logged in zoko */}
        </Route>
        <Route path={shopifyAppGrantRoutes.SHOPIFY_GRANT_PAYMENTS}>
          <ShopifyPayments />
          {/* when payments is due (first time) */}
        </Route>
        <Route path={shopifyAppGrantRoutes.SHOPIFY_GRANT_MATCHER}>
          <ShopifyStoreMatcher />
          {/* before redirecting to shopify access screen. */}
        </Route>
        <Route path={shopifyAppGrantRoutes.SHOPIFY_GRANT_INSTALL_COMPLETE}>
          <ShopifyInstallComplete />
        </Route>
      </Switch>
    </Suspense>
  )
  const cardContent = children ? children : content
  return (
    <div className={styles.shopifyGrantWrapper}>
      <div className={styles.content}>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://unpkg.com/@shopify/polaris@7.3.1/build/esm/styles.css"
          />
        </Helmet>
        <Provider config={_config}>
          <AppProvider>
            <Page>
              <Layout>
                <Layout.Section>
                  <Card sectioned>
                    {inIframe() ? cardContent : <Loading />}
                  </Card>
                </Layout.Section>
              </Layout>
            </Page>
          </AppProvider>
        </Provider>
      </div>
    </div>
  )
}

export default ShopifyGrant

ShopifyGrant.propTypes = {
  children: PropTypes.node,
}
