import { uniqBy } from 'lib/utils/array'

import actions from '../../actions'

const handleAssignmentEvent = ({ payload, stateObject }) => {
  // postActions is an object returned with configs for further actions
  const postActions = {
    playNotification: false, // The final state of this variable determines whether to play notification
  }

  const { setState, getState, dispatch } = stateObject
  const {
    viewAsId,
    conversationsList,
    selectedConversation,
    currentAgentTeams,
  } = getState()

  const updatedState = {} // This will hold the final updated state

  const { assignment, customer } = payload

  const existingConversationIdx = conversationsList.findIndex(
    ({ customerId }) => customerId === customer.id
  )

  const _removeConversation = async () => {
    if (existingConversationIdx > -1) {
      conversationsList.splice(existingConversationIdx, 1)

      updatedState.conversationsList = uniqBy(
        conversationsList,
        ({ customerId }) => customerId
      )
    }

    const isConversationOpen = selectedConversation?.customerId === customer?.id
    window.console.log(
      '_removeConversation',
      selectedConversation?.customerId,
      customer?.id
    )

    if (isConversationOpen) {
      // conversation is currently selected
      await dispatch(actions.clearPersistConversation())
      dispatch(actions.resetSelectedConversation())
    }
  }

  if (assignment.status === 'closed') {
    _removeConversation()
  } else if (assignment.status === 'assigned') {
    postActions.playNotification = true

    const team = currentAgentTeams?.find(({ id }) => id === assignment.id)
    const isAgentsTeam = team?.id && assignment.is_team

    if (viewAsId === assignment.id || isAgentsTeam) {
      const reConversation = {
        dp: customer.image_url,
        name: customer.name,
        isTeam: assignment.is_team,
        teamTag: team?.name,
        customerId: customer.id,
        unreadCount: customer.unread_count,
        lastMessageAt: assignment.timestamp, // this timestamp is the time of assignment, which may not be the timestamp of last message in the chat so the position of this chat may change after refresh
        lastIncomingMessageAt: customer.last_incoming_message_at,
      }

      conversationsList.unshift(reConversation)
      updatedState.conversationsList = uniqBy(
        conversationsList,
        ({ customerId }) => customerId
      )
    } else if (existingConversationIdx > -1) {
      _removeConversation()
    }
  }

  setState(updatedState)
  return postActions
}

export default handleAssignmentEvent
