import { createStore, createHook, defaults } from 'react-sweet-state'

import actions from './actions'
import initialState from './initialState'

defaults.devtools = true

const Store = createStore({
  name: 'ZOKO-service-' + window.location.origin,
  initialState,
  actions,
})

const service = createHook(Store, {
  selector: () => null,
})

export const useService = () => service()[1]

export const useToastService = createHook(Store, {
  selector: (state) => state.toast,
})

export const usePreviewService = createHook(Store, {
  selector: (state) => state.preview,
})

export const useAudioService = createHook(Store, {
  selector: (state) => state.audioUrl,
})

export const useAgentsTeamsList = createHook(Store, {
  selector: (state) => {
    const { teamsList, agentsList } = state
    return {
      teamsList,
      agentsList,
      membersList: [...agentsList, ...teamsList],
    }
  },
})

// TODO: remove this after August 2021
// temp hook for payment move to US (July 2021)
export const usePaymentBanner = createHook(Store, {
  selector: (state) => state.showPaymentBanner,
})

export const useChatPeekMessages = createHook(Store, {
  selector: (state) => ({
    chatPeekMessages: state.chatPeekMessages,
    chatPeekCustomerId: state.chatPeekCustomerId,
    chatPeekMessageLoader: state.chatPeekMessageLoader,
    chatPeekPageUpUrl: state.chatPeekNav.prev,
    chatPeekPageDownUrl: state.chatPeekNav.next,
    enableBottomLoader: !!state?.chatPeekSearchedMessage?.id,
    chatPeekSearchedMessage: state.chatPeekSearchedMessage,
    prevLastMessageId: state.prevLastMessageId,
  }),
})

export const useChatPeekLoader = createHook(Store, {
  selector: (state) => state.chatPeekMessageLoader,
})

export const useChatPeekHeader = createHook(Store, {
  selector: (state) => ({
    chatPeekHeaderName: state.chatPeekHeaderName,
    chatPeekHeaderPhone: state.chatPeekHeaderPhone,
    chatPeekHeaderLoader: state.chatPeekHeaderLoader,
    chatPeekHeaderTags: state.chatPeekHeaderTags,
    showAllHeaderTags: state.showAllHeaderTags,
    chatPeekHeaderChannel: state.chatPeekHeaderChannel,
  }),
})

export const useChatPeekId = createHook(Store, {
  selector: (state) => state.chatPeekCustomerId,
})

export const useLiquidDropdown = createHook(Store, {
  selector: ({ liquidVariablesLoader }) => ({
    liquidVariablesLoader,
  }),
})

export const useLiquidVariablesCategoryTab = createHook(Store, {
  selector: (state) => state.activeTab,
})
export const useTagSelector = createHook(Store, {
  selector: (state) => ({
    storeTags: state.storeTags,
    tagSearchText: state.tagSearchText,
  }),
})

export const useTemplateForm = createHook(Store, {
  selector: (state) => ({
    showAdvancedTemplateOptions: state.showAdvancedTemplateOptions,
    templateForm: state.templateForm,
    templateFormValidated: state.templateFormValidated,
  }),
})

export const useAnnouncement = createHook(Store, {
  selector: (state) => state.showAnnouncement,
})

export const useTimezoneData = createHook(Store, {
  selector: (state) => state.timezoneList,
})

export const useToolTipData = createHook(Store, {
  selector: (state) => ({
    toolTipText: state.toolTipText,
    toolTipPosition: state.toolTipPosition,
    toolTipType: state.toolTipType,
    showTooltip: state.showTooltip,
    toolTipOffset: state.toolTipOffset,
  }),
})

export const useToolTipSelector = createHook(Store, {
  selector: (state) => state.showAnnouncement,
})

export const useTagAdderData = createHook(Store, {
  selector: (state) => ({
    addTagsList: state.addTagsList,
    addTagsLoader: state.addTagsLoader,
  }),
})

export const useDateRangePicker = createHook(Store, {
  selector: (state) => state.showDateRangePicker,
})

export const useRangeFilterPreset = createHook(Store, {
  selector: ({ rangeFilterPreset }) => ({
    dateFrom: rangeFilterPreset?.dateFrom,
    dateTo: rangeFilterPreset?.dateTo,
    timezone: rangeFilterPreset?.timezone,
    timeFrom: rangeFilterPreset?.timeFrom,
    timeTo: rangeFilterPreset?.timeTo,
  }),
})

export const useConfitmation = createHook(Store, {
  selector: (state) => ({
    openConfirmation: state.openConfirmation,
    confirmationLoader: state.confirmationLoader,
    confirmation: state.confirmation,
  }),
})

export const useCursorPos = createHook(Store, {
  selector: (state) => state.cursorPos,
})
