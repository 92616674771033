import classNames from 'classnames'
import React from 'react'

import { useToolTipData } from '../store'
import styles from './ServiceTooltip.module.scss'

const ServiceTooltip = () => {
  const [
    { toolTipText, toolTipPosition, toolTipType, showTooltip, toolTipOffset },
  ] = useToolTipData()

  const toolTipClass = classNames(styles.toolTip, {
    [styles[toolTipType]]: toolTipType,
    [styles[toolTipPosition]]: toolTipPosition,
  })

  const toolTipStyle = (toolTipOffset) => ({
    ...toolTipOffset,
  })

  return showTooltip && toolTipText ? (
    <span
      className={toolTipClass + ' hidden-xs'}
      style={toolTipStyle(toolTipOffset)}
    >
      {toolTipText}
    </span>
  ) : null
}

export default ServiceTooltip
