const RealtimeMessageEvents = {
  messageIn: 'message:in',
  messageOut: 'message:out',
  messagePrivate: 'message:private',
  messageInternal: 'message:internal',
}

const RealtimeEvents = {
  ...RealtimeMessageEvents,
  messageDeliveryUpdate: 'message_delivery_update',
  chatAssignment: 'chat:assignment',
}

export { RealtimeEvents, RealtimeMessageEvents }
