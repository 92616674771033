import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Heading, Listbox } from '@shopify/polaris'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Icon, icons } from 'common/UI/Icon'
import { Loading } from 'common/UI/Loading'
import { settingsRoutes } from 'lib/config/routes'
import { getParams, inIframe } from 'lib/utils/url'
import { AccessCheck, accessCheck } from 'modules/Auth/AccessCheck'
import { useAuthHeaders, useStoreList } from 'modules/Auth/store'
import { useShopifyConnectLoader } from 'modules/Settings/pages/Integrations/store'

import styles from './ShopifyStoreMatcher.module.scss'

const ShopifyStoreMatcher = () => {
  const [{ storeList, currentStoreId }, authActions] = useStoreList()
  const [loader, actions] = useShopifyConnectLoader()
  const [headers] = useAuthHeaders()
  const history = useHistory()
  const shop = getParams('shop')
  const app = useAppBridge()
  useEffect(() => {
    const selectedStore = storeList.find(
      ({ store_domain: storeDomain }) => storeDomain === shop
    )
    window.console.log('CHECK', selectedStore, storeList, shop)
    if (selectedStore?.id === currentStoreId) {
      const redirectURL = `${window.location.origin}${settingsRoutes.INTEGRATIONS}`
      if (inIframe()) {
        app.dispatch(
          Redirect.toRemote({
            url: redirectURL,
            externalLink: true,
          })
        )
      } else {
        history.push(settingsRoutes.INTEGRATIONS)
      }
    } else if (selectedStore?.id) {
      authActions.setCurrentStore({
        headers,
        storeId: selectedStore?.id,
      })
    }
  }, [storeList, authActions, currentStoreId, history, shop, headers, app])
  return (
    <div className={styles.shopifyStoreMatcherWrapper}>
      <Heading>Select your organisation</Heading>
      {loader ? <Loading /> : null}
      <div className={styles.content}>
        <Listbox accessibilityLabel="Shopify store url">
          <Listbox.Option value={shop}>{shop}</Listbox.Option>
        </Listbox>
        &nbsp;
        <Icon source={icons.rightArrow} className={styles.arrow} />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <Listbox
          accessibilityLabel="Stores"
          onSelect={async (id) => {
            const storeData = storeList.find(
              ({ id: storeId }) => storeId === id
            )
            if (!storeData?.store_domain) {
              await actions.connectShopifyStore({ headers, id, shop, history })
              authActions.setCurrentStore({
                headers,
                storeId: id,
              })
            } else {
              window?.globals?.service?.toast(
                'This store is already connected',
                { type: 'error' }
              )
            }
          }}
        >
          {storeList
            .filter(
              ({ store_domain, role }) =>
                !store_domain &&
                accessCheck(role, AccessCheck.modules.integrations)
            )
            .map(({ id, name }) => (
              <Listbox.Option
                value={id}
                key={id}
                selected={currentStoreId === id}
              >
                {name}
              </Listbox.Option>
            ))}
          {storeList
            .filter(
              ({ store_domain, role }) =>
                !!store_domain ||
                !accessCheck(role, AccessCheck.modules.integrations)
            )
            .map(({ id, name }) => (
              <Listbox.Option value={id} key={id} disabled>
                {name}
              </Listbox.Option>
            ))}
        </Listbox>
      </div>
    </div>
  )
}

export default ShopifyStoreMatcher
