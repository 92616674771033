import { createStore, createHook, defaults } from 'react-sweet-state'

import config from 'lib/config'
import { checkDateWithinTimeperiod } from 'lib/dates'

import actions from './actions'
import initialState from './initialState'

defaults.devtools = true
const Store = createStore({
  name: 'ZOKO-chat-' + window.location.origin,
  initialState,
  actions,
})

export const useConverstationTab = createHook(Store, {
  selector: (state) => ({
    conversationTab: state.conversationTab,
    totalUnread: state.totalUnread,
    totalUnresponded: state.totalUnresponded,
  }),
})

export const useConverstations = createHook(Store, {
  selector: (state) => {
    return {
      list: state?.conversationsList,
      pagination: state?.conversationsPagination,
      selectedConversationId: state?.selectedConversation?.customerId,
      loadingConversations: state?.loadingConversations,
    }
  },
})

export const useChatMessages = createHook(Store, {
  selector: (state) => state.chatMessages,
})

export const useChatWindowExpired = createHook(Store, {
  selector: (state) => {
    const lastIncomingMessageAt =
      state.selectedConversation?.lastIncomingMessageAt

    const isWithin24H = checkDateWithinTimeperiod(
      lastIncomingMessageAt,
      config.WHATSAPP_EXPIRY_HOURS
    )
    const isWithin7days = checkDateWithinTimeperiod(
      lastIncomingMessageAt,
      config.MESSENGER_EXPIRY_HOURS
    )
    const channel = state?.selectedConversation?.channel || 'whatsapp'
    return {
      isChatExpired: !isWithin24H,
      channel,
      isHumanAgent: channel === 'whatsapp' ? false : isWithin7days,
    }
  },
})

export const useChatBox = createHook(Store, {
  selector: ({ chatBox }) => chatBox,
})

export const useChatSliderData = createHook(Store, {
  selector: (state) => {
    return {
      customerId: state?.selectedConversation?.customerId,
      customerName: state?.selectedConversation?.name,
      chatMessages: state?.chatMessages,
      pageUpUrl: state?.chatMessageNavigation?.prev,
      pageDownUrl: state?.chatMessageNavigation?.next,
      enableBottomLoader: !!state?.searchedMessage?.id,
      sendChatMessageLoader: state?.chatBox?.sendChatMessageLoader,
      searchedMessage: state?.searchedMessage,
      prevLastMessageId: state?.prevLastMessageId,
      loadingMessages: state?.loadingMessages,
      showEditTemplateModal: state?.chatBox?.showEditTemplateModal,
    }
  },
})

export const useChatMessagesData = createHook(Store, {
  selector: (state) => state.chatMessages,
})

export const useTemplateFormData = createHook(Store, {
  selector: (state) => {
    return {
      editTemplateId: state?.chatBox?.editTemplateId,
      showEditTemplateModal: state?.chatBox?.showEditTemplateModal,
      quickTemplates: state?.chatBox?.quickTemplates,
    }
  },
})

export const useConversationSearch = createHook(Store, {
  selector: (state) => ({
    conversationSearch: state.conversationSearch,
  }),
})

export const useChatBoxMessage = createHook(Store, {
  selector: (state) => ({
    message: state?.chatBox?.message,
    isNotesMode: state?.chatBox?.isNotesMode,
    sendChatMessageLoader: state?.chatBox?.sendChatMessageLoader,
    isWriting:
      state?.chatBox?.message?.length || state?.chatBox?.attachments?.length,
    channel: state?.selectedConversation?.channel,
  }),
})

export const useSendChatMessageLoader = createHook(Store, {
  selector: (state) => ({
    sendChatMessageLoader: state?.chatBox?.sendChatMessageLoader,
    canSendMessage:
      state?.chatBox?.message || state?.chatBox?.attachments?.length,
    attachments: state?.chatBox?.attachments,
  }),
})

export const useAttachments = createHook(Store, {
  selector: (state) => ({
    attachments: state.chatBox.attachments,
    isNotesMode: state?.chatBox?.isNotesMode,
  }),
})

export const useSelectedQuickOption = createHook(Store, {
  selector: (state) => state.chatBox.selectedQuickOption,
})

export const useQuickReplies = createHook(Store, {
  selector: (state) => ({
    quickReplies: state.quickReplies,
    quickRepliesLoader: state.quickRepliesLoader,
    nextPageUrl: state.quickRepliesPagination?.next_page_url,
    quickRepliesFilter: state.quickRepliesFilter,
    currentPageNo: state.quickRepliesPagination?.current_page_no,
  }),
})

export const useQuickRepliesSearch = createHook(Store, {
  selector: (state) => ({
    quickRepliesSearchText: state.quickRepliesSearchText,
    quickRepliesFilter: state.quickRepliesFilter,
  }),
})

export const useQuickTemplatesSearch = createHook(Store, {
  selector: (state) => state.quickTemplatesSearchText,
})

export const useQuickTemplates = createHook(Store, {
  selector: (state) => ({
    quickTemplates: state.chatBox.quickTemplates,
    quickTemplatesLoader: state.chatBox.quickTemplatesLoader,
    quickTemplatesSearchText: state.quickTemplatesSearchText,
  }),
})

export const useQuickOptions = createHook(Store, {
  selector: (state) => state.showQuickOptions,
})

export const useDock = createHook(Store, {
  selector: (state) => state.dockTabs,
})

export const useSelectedConversation = createHook(Store, {
  selector: ({
    selectedConversation,
    loadingSelectedConversationData,
    manageChatLoader,
  }) => ({
    ...selectedConversation,
    loadingSelectedConversationData,
    manageChatLoader,
  }),
})

export const useSendButtonData = createHook(Store, {
  selector: (state) => state?.chatBox?.disableSendButton,
})

export const useViewAsId = createHook(Store, {
  selector: (state) => state.viewAsId,
})

export const useContactResults = createHook(Store, {
  selector: (state) => ({
    contactSearchResults: state.contactSearchResults,
    selectedConversationId: state.selectedConversation?.customerId,
  }),
})

export const useOpenMenu = createHook(Store, {
  selector: (state) => state.openMenu,
})

export const useEmojiPanel = createHook(Store, {
  selector: (state) => ({
    showEmojiPanel: state?.showEmojiPanel,
    message: state?.chatBox?.message,
  }),
})

export const useShowReAssign = createHook(Store, {
  selector: (state) => state.showReAssign,
})

export const useAgentSidebar = createHook(Store, {
  selector: ({ showAgentSidebar, viewAsId, showAgentsPopup }) => ({
    viewAsId,
    showAgentsPopup,
    showAgentSidebar,
  }),
})

export const useConversationListOfflineToast = createHook(Store, {
  selector: (state) => state.chatOffline,
})

export const useRequestPermissionLoader = createHook(Store, {
  selector: (state) => state.requestPermissionLoader,
})
