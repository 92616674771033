import ctz from 'countries-and-timezones'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { formatDate } from '.'

dayjs.extend(utc)
dayjs.extend(timezone)

const _timezones = Object.values(ctz.getAllTimezones())

const timezones = _timezones.map((t) => {
  return {
    ...t,
    label: t.name,
    value: t.name,
  }
})

const getLocalTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone
const getTimezoneDetails = (name) => ctz.getTimezone(name)
const getTimezoneParsedUTC = (date, time, timezone) => {
  // Create dayjs object with time, date and timezone
  const timeDateTimezone = dayjs(
    `${formatDate(date, '', 'YYYY-MM-DD')} ${time}`
  ).tz(timezone, true)

  // Get the utc time
  const _result = dayjs(timeDateTimezone).utc()

  // Convert to timestamp in epoch milliseconds
  const result = formatDate(_result, '', 'x')

  return Number(result)
}

const getISOFormat = ({ date, time, timezone }) => {
  const formatedDate = formatDate(date, '', 'YYYY-MM-DD')
  const timezoneApplied = dayjs(`${formatedDate} ${time}`).tz(timezone, true)
  return formatDate(timezoneApplied, '', 'YYYY-MM-DDTHH:mm:ss Z')
}

const getCustomTimeFormat = ({ date, time, timezone, format }) => {
  const formatedDate = formatDate(date || dayjs(), '', 'YYYY-MM-DD')
  const timezoneApplied = dayjs(`${formatedDate} ${time}`).tz(timezone, true)
  return formatDate(timezoneApplied, '', format)
}

export {
  timezones,
  getLocalTimeZone,
  getTimezoneDetails,
  getTimezoneParsedUTC,
  getISOFormat,
  getCustomTimeFormat,
}
