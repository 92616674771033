import React from 'react'

import { getParams } from 'lib/utils/url'

import ShopifyRedirection from '../ShopifyRedirection'
import styles from './ShopifyInstallComplete.module.scss'

const ShopifyInstallComplete = () => {
  return (
    <div className={styles.shopifyInstallCompleteWrapper}>
      <ShopifyRedirection
        title="Installation complete"
        description="Please wait"
        instantRedirect
        redirectURL={getParams('redirect_uri')}
        externalLink
        actionText="PROCEED"
      />
    </div>
  )
}

export default ShopifyInstallComplete
