import { debouncer } from 'lib/debouncer'
import { uniqBy } from 'lib/utils/array'
import { conversationTabs } from 'modules/Chat/constants'
import { RealtimeEvents } from 'modules/Socket/realtimeEvents'

import actions from '../../actions'
import { mapRealtimeMessage } from '../../mapper'

const handleMessageEvents = ({ payload, eventName, stateObject, headers }) => {
  // postActions is an object returned with configs for further actions
  const postActions = {
    playNotification: false, // The final state of this variable determines whether to play notification
  }

  const { setState, getState, dispatch } = stateObject
  const {
    viewAsId,
    chatMessages,
    currentAgentTeams,
    conversationsList,
    conversationTab,
    selectedConversation,
  } = getState()
  const updatedState = {} // This will hold the final updated state
  const { assignment, customer, message } = payload

  const assignedTeam = currentAgentTeams?.find(({ id }) => id === assignment.id)
  const isAgentsTeam = assignedTeam?.id && assignment.is_team
  const isConversationOpen = selectedConversation?.customerId === customer?.id

  const reConversation = {
    dp: customer.image_url,
    name: customer.name,
    isTeam: assignment.is_team,
    teamTag: assignedTeam?.name,
    isUnread: !isConversationOpen && !!customer.unread_count,
    customerId: customer.id,
    unreadCount: customer.unread_count,
    lastMessage: message.text,
    lastMessageAt: message.platform_timestamp,
    lastIncomingMessageAt: customer.last_incoming_message_at,
  }

  if (eventName === RealtimeEvents.messageIn) {
    /* 
      For all message events except "message:in", the last_incoming_message_at is in the customer payload itself.
      But for message:in, we have to set the lastIncomingMessageAt from message timestamp.
    */
    reConversation.lastIncomingMessageAt = message.platform_timestamp
  }

  if (viewAsId === assignment.id || isAgentsTeam) {
    /* 
      Have to update only if assigned to the current viewAsId
      or any of the current agent's teams
    */

    updatedState.conversationsList = conversationsList

    const existingConversation = updatedState.conversationsList.find(
      ({ customerId }) => customerId === reConversation.customerId
    )

    if (existingConversation) {
      // customer already exists in the conversations list

      // remove the existing conversation from conversationsList
      updatedState.conversationsList = conversationsList.filter(
        ({ customerId }) => customerId !== reConversation.customerId
      )
    } else {
      // Customer doesn't exist in the conversations list

      if (eventName === RealtimeEvents.messageInternal) {
        // hack for handling assignment status "close"
        // Actual solution: ask the backend mqtt to not set the assignment id if conv is closed.
        return
      }
    }

    // Now update the conversation list itself:
    // 1. Append the conversation to the start of the list
    if (
      (conversationTab === conversationTabs.unread.value &&
        reConversation.isUnread) ||
      (conversationTab === conversationTabs.unreplied.value &&
        eventName === RealtimeEvents.messageIn) ||
      conversationTab === conversationTabs.all.value
    ) {
      updatedState.conversationsList = [
        { ...existingConversation, ...reConversation }, // Persist all other existing data in the conversation item
        ...updatedState.conversationsList,
      ]
    }
    // 2. Remove any duplicates in the conversationsList (Just to be safe!)
    updatedState.conversationsList = uniqBy(
      updatedState.conversationsList,
      ({ customerId }) => customerId
    )

    /* 
      Set playNotification when there is an incoming message and 
      the conversation is currently not selected
    */
    if (eventName === RealtimeEvents.messageIn && !isConversationOpen)
      postActions.playNotification = true
  }

  if (isConversationOpen) {
    // Conversation is currently selected

    // Update Chat Messages list
    updatedState.chatMessages = uniqBy(
      [mapRealtimeMessage(message), ...chatMessages],
      ({ messageId }) => messageId
    )

    // update selectedConversation lastIncomingMessageAt (for the 24h window)
    dispatch(actions.updateSelectedConversationData({ headers }))

    // Call mark as read
    // Hack fix: call api
    // Proper fix: use mqtt
    dispatch(
      actions.chatSetReadStatus({
        headers,
        customerId: customer.id,
        read: true,
        hideToast: true,
      })
    )
  }

  // Update unread/unreplied counts
  // Hack fix: call api
  // Proper fix: use mqtt
  debouncer(() => {
    // this is to avoid repeated calls during broadcast or quick find bulk assignments.
    dispatch(actions.fetchCurrentAgentChatsCount({ headers }))
  }, 3000)

  setState(updatedState)
  return postActions
}

export default handleMessageEvents
