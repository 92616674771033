const ListOfIcns = {
  arrowElbowDownLeft: require('./files/arrow-elbow-down-left.svg').default,
  alt: require('./files/alt.svg').default,
  calendar: require('./files/calendar.svg').default,
  caretDown: require('./files/caret-down.svg').default,
  caretDownLight: require('./files/caret-down-light.svg').default,
  circleLoading: require('./files/circle-loading.svg').default,
  chatBubble: require('./files/chat-bubble.svg').default,
  chatBubbleOutline: require('./files/chat-bubble-outline.svg').default,
  chatBubbleOutlineSelected: require('./files/chat-bubble-outline-selected.svg')
    .default,
  chatBalloon: require('./files/chat-balloon.svg').default,
  clip: require('./files/clip.svg').default,
  clipLight: require('./files/clip-light.svg').default,
  close: require('./files/close.svg').default,
  closeChat: require('./files/close-chat.svg').default,
  document: require('./files/document.svg').default,
  dot: require('./files/dot.svg').default,
  dots: require('./files/dots.svg').default,
  dotsCircle: require('./files/dots-circle.svg').default,
  dragDots: require('./files/drag-dots.svg').default,
  emoji: require('./files/emoji.svg').default,
  unknownFile: require('./files/unknown-file.svg').default,
  filter: require('./files/filter.svg').default,
  iconPlaceholder: require('./files/icon-placeholder.svg').default,
  image: require('./files/image.svg').default,
  info: require('./files/info.svg').default,
  instagram: require('./files/instagram.svg').default,
  leftArrow: require('./files/left-arrow.svg').default,
  rightArrow: require('./files/right-arrow.svg').default,
  lessCircle: require('./files/less-circle.svg').default,
  lessCircleLight: require('./files/lessCircleLight.svg').default,
  link: require('./files/link.svg').default,
  location: require('./files/location.svg').default,
  logo: require('./files/logo.svg').default,
  magnifierOutline: require('./files/magnifier-outline.svg').default,
  microphone: require('./files/microphone.svg').default,
  microphoneLight: require('./files/microphone-light.svg').default,
  morePhotos: require('./files/more-photos.svg').default,
  notes: require('./files/notes.svg').default,
  notesLight: require('./files/notes-light.svg').default,
  online: require('./files/online.svg').default,
  offline: require('./files/offline.svg').default,
  orders: require('./files/orders.svg').default,
  panelIndicator: require('./files/panel-indicator.svg').default,
  pencil: require('./files/pencil.svg').default,
  pinOutline: require('./files/pin-outline.svg').default,
  play: require('./files/play.svg').default,
  playLight: require('./files/play-light.svg').default,
  playDark: require('./files/play-dark.svg').default,
  plus: require('./files/plus.svg').default,
  plusOutline: require('./files/plus-outline.svg').default,
  plusOutlineWhite: require('./files/plusOutlineWhite.svg').default,
  plusCircle: require('./files/plus-circle.svg').default,
  phone: require('./files/phone.svg').default,
  queue: require('./files/queue.svg').default,
  queueOutline: require('./files/queue-outline.svg').default,
  queueOutlineSelected: require('./files/queue-outline-selected.svg').default,
  quickReply: require('./files/quick-reply.svg').default,
  recentClock: require('./files/recent-clock.svg').default,
  sendToChat: require('./files/send-to-chat.svg').default,
  search: require('./files/search.svg').default,
  send: require('./files/send.svg').default,
  sendWhite: require('./files/send-white.svg').default,
  sendAudio: require('./files/send-audio.svg').default,
  settings: require('./files/settings.svg').default,
  shortcut: require('./files/shortcut.svg').default,
  smileOutline: require('./files/smile-outline.svg').default,
  smileLightOutline: require('./files/smile-outline-light.svg').default,
  squares: require('./files/squares.svg').default,
  storeBag: require('./files/store-bag.svg').default,
  stopLight: require('./files/stop-light.svg').default,
  tag: require('./files/tag.svg').default,
  tagBold: require('./files/tag_bold.svg').default,
  threeDots: require('./files/three-dots.svg').default,
  type: require('./files/type.svg').default,
  typeLight: require('./files/type-light.svg').default,
  users: require('./files/users.svg').default,
  stop: require('./files/stop.svg').default,
  xCircle: require('./files/x-circle.svg').default,
  xCircleLight: require('./files/x-circle-light.svg').default,
  zap: require('./files/zap.svg').default,
  checkCircle: require('./files/check-circle.svg').default,
  whiteClose: require('./files/white-close.svg').default,
  errorCircle: require('./files/error-circle.svg').default,
  zapWhite: require('./files/zap-white.svg').default,
  zapLight: require('./files/zap-light.svg').default,
  read: require('./files/read.svg').default,
  delivered: require('./files/delivered.svg').default,
  accepted: require('./files/accepted.svg').default,
  picture: require('./files/picture.svg').default,
  dotMenu: require('./files/dot-menu.svg').default,
  closeGrey: require('./files/close-grey.svg').default,
  tick: require('./files/tick.svg').default,
  templates: require('./files/templates.svg').default,
  download: require('./files/download.svg').default,
  downloadOutline: require('./files/download-outline.svg').default,
  productListEmpty: require('./files/product-list-empty.svg').default,
  xPurple: require('./files/xPurple.svg').default,
  xBlue: require('./files/xBlue.svg').default,
  xBrown: require('./files/xBrown.svg').default,
  addAddress: require('./files/addAddress.svg').default,
  newTag: require('./files/newTag.svg').default,
  tickAccent: require('./files/tick-accent.svg').default,
  tickBlack: require('./files/tick-black.svg').default,
  dockClose: require('./files/dock-close.svg').default,
  dockOpen: require('./files/dock-open.svg').default,
  chatHeaderPin: require('./files/chatHeaderPin.svg').default,
  searchClose: require('./files/searchClose.svg').default,
  magnifier: require('./files/magnifier.svg').default,
  arrowDown16: require('./files/arrowDown16.svg').default,
  arrowDownOrange: require('./files/arrowDownOrange.svg').default,
  orders24: require('./files/orders24.svg').default,
  bag24: require('./files/bag24.svg').default,
  calendar24: require('./files/calendar24.svg').default,
  tags24: require('./files/tags24.svg').default,
  tag16: require('./files/tag16.svg').default,
  user24: require('./files/user24.svg').default,
  dockMobile: require('./files/dock-mobile.svg').default,
  openmenu24: require('./files/openmenu.svg').default,
  computer16: require('./files/computer.svg').default,
  goToChat: require('./files/go-to-chat.svg').default,
  somethingWentWrong: require('./files/somethingWentWrong.svg').default,
  overview: require('./files/overview.svg').default,
  rupis: require('./files/rupis.svg').default,
  reports: require('./files/reports.svg').default,
  people: require('./files/people.svg').default,
  contacts: require('./files/contacts.svg').default,
  bots: require('./files/bots.svg').default,
  bolt: require('./files/bolt.svg').default,
  broadcast: require('./files/broadcast.svg').default,
  routing: require('./files/routing.svg').default,
  autoRouting: require('./files/auto_routing.svg').default,
  routingRules: require('./files/routing_rules.svg').default,
  templateMenu: require('./files/templates-menu.svg').default,
  apikey: require('./files/integrations.svg').default,
  messageLogs: require('./files/message-logs.svg').default,
  webHooks: require('./files/webhooks.svg').default,
  reAssign20: require('./files/re-assign.svg').default,
  reAssignLight: require('./files/reAssignLight.svg').default,
  markAsRead: require('./files/markAsRead.svg').default,
  markAsUnread: require('./files/markAsUnread.svg').default,
  mediaEmpty: require('./files/media-empty.svg').default,
  trash: require('./files/trash.svg').default,
  trashRed: require('./files/trashRed.svg').default,
  clock: require('./files/clock.svg').default,
  apps: require('./files/apps.svg').default,
  appsSelected: require('./files/appsSelected.svg').default,
  broadcastcard: require('./files/broadcastcard.svg').default,
  zokoLogo: require('./files/zoko-logo.svg').default,
  gmailLogo: require('./files/gmail-logo.svg').default,
  facebookLogo: require('./files/facebook-logo.svg').default,
  add: require('./files/add.svg').default,
  externalLink: require('./files/external-link.svg').default,
  video: require('./files/video.svg').default,
  successTick: require('./files/success_tick.svg').default,
  optionsMore: require('./files/optionsMore.svg').default,
  warningCircle: require('./files/warning-circle.svg').default,
  whatsappLogoFill: require('./files/whatsapp-logo.svg').default,
  magentoLogo: require('./files/magento-logo.svg').default,
  shopifyLogo: require('./files/shopify-logo.svg').default,
  greenCheckCircle: require('./files/green-check-circle.svg').default,
  closeDark: require('./files/closeDark.svg').default,
  trashBlack: require('./files/trashBlack.svg').default,
  eye: require('./files/eye.svg').default,
  copy: require('./files/copy.svg').default,
  filterText: require('./files/filter_document.svg').default,
  filterDocument: require('./files/filter_document.svg').default,
  filterGif: require('./files/filter_gif.svg').default,
  filterImage: require('./files/filter_image.svg').default,
  filterVideo: require('./files/filter_video.svg').default,
  darkGreenTick: require('./files/dark-green-tick.svg').default,
  mediaPin: require('./files/mapPin.svg').default,
  mediaDocument: require('./files/mediaDocument.svg').default,
  mediaPicture: require('./files/mediaPicture.svg').default,
  mediaPlay: require('./files/mediaPlay.svg').default,
  addButtonPrimary: require('./files/addButtonPrimary.svg').default,
  infoDark: require('./files/infoDark.svg').default,
  tickGreen: require('./files/tickGreen.svg').default,
  pencilWhite: require('./files/pencilWhite.svg').default,
  eyeClosed: require('./files/eyeClosed.svg').default,
  agents: require('./files/agents.svg').default,
  greenDot: require('./files/dot-green.svg').default,
  redDot: require('./files/dot-red.svg').default,
  availability: require('./files/availability.svg').default,
  userRole: require('./files/user-role.svg').default,
  integrations: require('./files/PuzzlePiece.svg').default,
  flows: require('./files/flows.svg').default,
  messengerLight: require('./files/messengerLight.svg').default,
  tagBlack: require('./files/tag-black.svg').default,
  tagBlue: require('./files/tag-blue.svg').default,
  tagGreen: require('./files/tag-green.svg').default,
  tagPurple: require('./files/tag-purple.svg').default,
  tagRed: require('./files/tag-red.svg').default,
  tagYellow: require('./files/tag-yellow.svg').default,
  tagBrown: require('./files/tag-brown.svg').default,
  tagGrey: require('./files/tag-grey.svg').default,
  tagOrange: require('./files/tag-orange.svg').default,
  zapPrimary: require('./files/flowsTrigger.svg').default,
  clockDark: require('./files/clockDark.svg').default,
  exitFlag: require('./files/exitFlag.svg').default,
  ifCondition: require('./files/ifCondition.svg').default,
  newContact: require('./files/new-contact.svg').default,
  magnifierPlus: require('./files/magnifierPlus.svg').default,
  clockClockwise: require('./files/clockClockwise.svg').default,
  buttonMessage: require('./files/button-message.svg').default,
  listMessage: require('./files/list-message.svg').default,
  yellowPin: require('./files/yellowPin.svg').default,
  documentLight: require('./files/document-light.svg').default,
  imageLight: require('./files/image-light.svg').default,
  videoLight: require('./files/video-light.svg').default,
  bounceBack: require('./files/bounce-back.svg').default,
  calendarWait: require('./files/calendar-wait.svg').default,
  hourglass: require('./files/hourglass.svg').default,
  agentChat: require('./files/agent-chat.svg').default,
  duoChating: require('./files/duo-chating.svg').default,
  hourglassLight: require('./files/hourglass-light.svg').default,
  like: require('./files/like.svg').default,
  dislike: require('./files/dislike.svg').default,
  downloadWhite: require('./files/download_white.svg').default,
  likeBlack: require('./files/likeBlack.svg').default,
  arrowDownGrey: require('./files/arrowDownGrey.svg').default,
  vitals: require('./files/vitals.svg').default,
  chatPrimary: require('./files/chat-primary.svg').default,
  externalLinkBlack: require('./files/external-link-black.svg').default,
  duoChattingBlack: require('./files/duo-chatting-black.svg').default,
  plusGrey: require('./files/plus-grey.svg').default,
  removeTag: require('./files/remove-tag.svg').default,
  backArrowGrey: require('./files/backArrowGrey.svg').default,
  zoko: require('./files/zoko.svg').default,
  zokoWaterMark: require('./files/zoko-water-mark.svg').default,
  leftArrowGrey: require('./files/leftArrowGrey.svg').default,
  starSelected: require('./files/star-selected.svg').default,
  starUnselected: require('./files/star-unselected.svg').default,
  circleTick: require('./files/tick_circle.svg').default,
  circleClock: require('./files/clock_circle.svg').default,
  circleClose: require('./files/close_circle.svg').default,
  circleTrigger: require('./files/trigger_circle.svg').default,
  lightContacts: require('./files/contacts_light.svg').default,
  lightList: require('./files/list_light.svg').default,
  lightPencil: require('./files/pencil_light.svg').default,
  starBlackOutline: require('./files/star-black-outline.svg').default,
  ratingsReviews: require('./files/ratings-review.svg').default,
  moneyBag: require('./files/money-bag.svg').default,
  bagPrimary: require('./files/bag-primary.svg').default,
  clockPrimary: require('./files/clock-primary.svg').default,
  shippingPrimary: require('./files/shipping-primary.svg').default,
  cartCancelled: require('./files/cart-cancelled.svg').default,
  exitCondition: require('./files/exit-condition.svg').default,
  errorFilled: require('./files/errorFilled.svg').default,
  outIconDark: require('./files/outIconDark.svg').default,
  inIconDark: require('./files/inIconDark.svg').default,
  refreshIconWhite: require('./files/refreshIconWhite.svg').default,
  emailLight: require('./files/email-light.svg').default,
  facebookRound: require('./files/facebook-round.svg').default,
  fbLogoRound: require('./files/fb_logo_round.png').default,
  instagramLogo: require('./files/instagram.png').default,
  pin: require('./files/pin.svg').default,
  whatsapp: require('./files/whatsapp.svg').default,
  plusPrimary: require('./files/plus-primary.svg').default,
  overviewActive: require('./files/overview_active.svg').default,
  overviewGrey: require('./files/overview_grey.svg').default,
  settingsGrey: require('./files/settingsGrey.svg').default,
  dragButton: require('./files/drag-button.svg').default,
  user: require('./files/user.svg').default,
  email: require('./files/email.svg').default,
  announce: require('./files/announce.svg').default,
  broadCastSegment: require('./files/broadcast-segment.svg').default,
  plusCircleLight: require('./files/plus-circle-light.svg').default,
  shoppingCart: require('./files/shopping-cart.svg').default,
  segment: require('./files/segment.svg').default,
  caseSensitive: require('./files/case-sensitive.svg').default,
  caseInsensitive: require('./files/case-insensitive.svg').default,
  infoGray: require('./files/info-gray.svg').default,
  uploadArrow: require('./files/upload-arrow.svg').default,
  externalLinkGrey: require('./files/external-link-grey.svg').default,
  genericTag: require('./files/generic_tag.svg').default,
  searchGrey: require('./files/search_grey.svg').default,
  startChat: require('./files/start-chat.svg').default,
  genericSegment: require('./files/generic_segment.svg').default,
  systemTagRound: require('./files/system-tag-round.svg').default,
  shopifyTagRound: require('./files/shopify-tag-round.svg').default,
  zokoTagRound: require('./files/zoko-tag-round.svg').default,
  undo: require('./files/undo.svg').default,
  optOutIcon: require('./files/optOutIcon.svg').default,
  dataIconLight: require('./files/dataIconLight.svg').default,
  peopleLight: require('./files/peopleLight.svg').default,
  closeOrange: require('./files/close-orange.svg').default,
  translate: require('./files/translate.svg').default,
}

export default ListOfIcns
