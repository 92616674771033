const firebaseConfig = {
  apiKey: 'AIzaSyDdKWQTki4uCsRF857uUH931FS_--BqKx8',
  authDomain: 'zoko-web2-test.firebaseapp.com',
  databaseURL: 'https://zoko-web2-test.firebaseio.com',
  projectId: 'zoko-web2-test',
  storageBucket: 'zoko-web2-test.appspot.com',
  messagingSenderId: '949195383124',
  appId: '1:949195383124:web:c8eaddea6bf50b04d0a8c1',
  measurementId: 'G-JR9H5KZ1LW',
  FCMWebPushCertificate:
    'BAK0QCS_tZK0t5VqWFdRNj-GsOc8mgMMAeKtMmySjTsW2EsrwbMuwoCRX1CCyw_4vS7QYm4EblWjyqBBmVoIqRY',
}

exports.firebaseConfig = firebaseConfig
