import { uniqBy } from 'lib/utils/array'
import { noTagsApplied } from 'lib/utils/tagManager'

export const chatPeekHeader = (chatPeekHeaderResponse) => {
  return {
    chatPeekHeaderName: chatPeekHeaderResponse.name,
    chatPeekHeaderPhone: chatPeekHeaderResponse.phone,
    chatPeekHeaderTags: chatPeekHeaderResponse?.tags,
    chatPeekHeaderChannel: chatPeekHeaderResponse?.channel,
  }
}

export const storeTagsMapper = (options = [], showNoTagsApplied) => {
  const _options = uniqBy(
    [
      ...options?.map?.((option) => ({
        label: option.tag,
        value: option.tag,
      })),
    ],
    ({ value }) => value
  )
  return showNoTagsApplied
    ? [
        {
          label: 'NO TAGS APPLIED',
          value: noTagsApplied,
        },
        ..._options,
      ]
    : _options
}

export const timezoneMapper = (data = []) => {
  return data?.map(({ name, key }) => {
    return {
      label: name,
      value: key,
    }
  })
}
