export default {
  shopify: {
    guestDomain: '',
    zokoShopifyId: '',
    nativeDomain: '',
    host: '',
    nativeNonce: '',
    connectStoreLoader: false,
    installLoader: false,
    isConnected: false,
    showRetry: false,
  },
  facebook: {
    pagesList: [],
    userName: '',
    selectedPageId: '',
    loader: false,
    isConnected: false,
    connectionName: '',
    link: 'www.facebook.com/',
    connectionImg: '',
  },
  instagram: {
    pagesList: [],
    userName: '',
    selectedPageId: '',
    loader: false,
    isConnected: false,
    link: 'www.instagram.com/',
    connectionName: '',
    connectionImg: '',
  },
  whatsapp: {
    isConnected: true,
    link: 'www.wa.me/',
    connectionName: '',
    connectionImg: '',
  },
  pageLoader: true,
}
