import localforage from 'localforage'
import React, { useEffect } from 'react'

import { Button } from 'common/UI/Button'
import { Icon, icons } from 'common/UI/Icon'
import { Modal } from 'common/UI/Modal'
import { Text } from 'common/UI/Text'
import Images from 'common/assets/images'
import { appsRoutes } from 'lib/config/routes'
import trackEvent from 'lib/trackEvent'
import { useHostInfo } from 'modules/Auth/store'

import { useAnnouncement } from '../../store'
import styles from './Pricing.module.scss'

const storageKey = 'broadcastAnnounce'

const Pricing = () => {
  const [showAnnouncement, actions] = useAnnouncement()
  const [hostInfo] = useHostInfo()
  useEffect(() => {
    const check = async () => {
      const _showAnnouncement = await localforage.getItem(storageKey)
      actions.setAnnouncement(_showAnnouncement)
    }
    check()
  }, [actions])

  useEffect(() => {
    if (showAnnouncement !== 'hide' || !hostInfo?.showBilling) {
      trackEvent(
        trackEvent.modules.service,
        trackEvent.events.show_broadcast_news
      )
    }
  }, [showAnnouncement, hostInfo])

  const hideAnnouncement = async () => {
    await localforage.setItem(storageKey, 'hide')
    actions.setAnnouncement('hide')
  }

  const onClose = () => {
    hideAnnouncement()
    trackEvent(
      trackEvent.modules.service,
      trackEvent.events.ignore_broadcast_button
    )
  }

  const onAccept = () => {
    hideAnnouncement()
    window.location.href = appsRoutes.BROADCAST
    trackEvent(
      trackEvent.modules.service,
      trackEvent.events.enable_broadcast_button
    )
  }

  const onLearnMore = () => {
    hideAnnouncement()
    trackEvent(
      trackEvent.modules.service,
      trackEvent.events.learn_more_broadcast_button
    )
    window.open(
      'https://learning.zoko.io/micro-lessons/dynamic-links-conversion-tracking'
    )
  }

  if (showAnnouncement === 'hide' || !hostInfo?.showBilling) return null
  return (
    <Modal
      isOpen
      wrapperClass={styles.pricingWrapper}
      noPadding
      onClose={onClose}
    >
      <div className={styles.content}>
        <Icon
          source={icons.close}
          className={styles.closeIcon}
          size={`var(--p24)`}
          onClick={onClose}
        />
        <div className={styles.imageSection}>
          <img
            alt="Broadcast alert"
            src={Images.broadCastAnnounce}
            className={styles.billingAlert}
          />
        </div>
        <div className={styles.newFeature}>
          &nbsp;&nbsp;Announcement!&nbsp;&nbsp;
        </div>
        <div className={styles.textContent}>
          <Text fontSize={Text.fontSize.p24} className={styles.header}>
            Introducing Broadcast 2.0
          </Text>
          <Text fontSize={Text.fontSize.p14} className={styles.p}>
            <>
              Your Broadcasts just got smarter!
              <br />
              <br /> Zoko now automatically tracks conversions from your
              broadcasts. Showing you how much money you made from each
              broadcast.
              <br />
              <br /> All dynamic links in your message will be converted to a
              unique tracking links to help you track clicks, responses and even
              conversions!
            </>
          </Text>
          <br />
          <div className={styles.footer}>
            <Button size="sm" onClick={onLearnMore}>
              <span className="hidden-xs">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              Learn more
              <span className="hidden-xs">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </Button>
            <span className="hidden-xs">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            &nbsp;&nbsp;
            <Button
              colorScheme="primary"
              size="sm"
              variant="outline"
              onClick={onAccept}
            >
              Do a Broadcast with Tracking
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Pricing
