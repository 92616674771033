import i18n from 'lib/i18n'

export default {
  toast: [],

  //preview
  preview: {
    url: [],
    options: {},
    currentIndex: 0,
  },

  audioUrl: null,
  teamsList: [],
  agentsList: [],
  showPaymentBanner: false,
  // chatpeek
  chatPeekCustomerId: '',
  chatPeekMessages: [],
  chatPeekMessageLoader: false,
  chatPeekNav: {
    next: null,
    prev: null,
  },
  chatPeekSearchedMessage: {
    id: null,
    timestamp: null,
    show: false, // Setting this to true, scroll the searched message into view
  },
  prevLastMessageId: '',
  chatPeekHeaderLoader: false,
  chatPeekHeaderName: '',
  chatPeekHeaderPhone: '',
  chatPeekHeaderTags: [],
  chatPeekHeaderChannel: '',
  showAllHeaderTags: false,
  // end
  //Liquid-dropdown
  liquidVariablesLoader: false,
  activeTab: 'zoko',
  //templateform
  showAdvancedTemplateOptions: false,
  templateForm: [],
  templateFormValidated: false,
  showAnnouncement: 'hide',
  timezoneList: [],

  //tooltip
  toolTipText: '',
  toolTipPosition: '',
  toolTipType: '',
  showTooltip: false,
  toolTipOffset: {},

  //tagAdder
  addTagsList: [],
  addTagsLoader: false,
  showDateRangePicker: false,
  rangeFilterPreset: {
    dateFrom: '',
    dateTo: '',
    timezone: '',
    timeFrom: '',
    timeTo: '',
  },
  openConfirmation: false,
  confirmationLoader: false,
  confirmation: {
    message: i18n.t('confirmation.title'),
    colorScheme: 'primary',
    submitText: i18n.t('confirmation.submitText'),
    cancelText: i18n.t('confirmation.cancelText'),
  },
  cursorPos: 0,
  appVersion: null,
  appVersionLoader: false,
}
