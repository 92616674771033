/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useRef } from 'react'

import { useAudioService } from '../store'
import styles from './AudioService.module.scss'

const AudioService = () => {
  const [audioUrl, actions] = useAudioService()
  const audioRef = useRef()
  const element = audioRef.current
  useEffect(() => {
    if (element && audioUrl) {
      element.src = audioUrl
      try {
        element?.play()
      } catch (e) {
        window.console.error('play failed due to lack of user interaction.', e)
      }
      actions.playAudio(null)
    }
  }, [element, audioUrl, actions])

  return <audio ref={audioRef} className={styles.wrapper} />
}

export default AudioService
