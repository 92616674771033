import Bugsnag from '@bugsnag/js'
import mixpanel from 'mixpanel-browser'

import config from 'lib/config'

const setTrackingProfile = ({ userData }) => {
  // Set Bugsnag user
  Bugsnag.setUser(userData.agentId, userData.email, userData.name)

  // Mixpanel
  if (config.MIXPANEL) {
    mixpanel.identify(userData.agentId)
    mixpanel.people.set({
      USER_ID: userData.agentId,
      $email: userData.email, // only reserved properties need the $
      $name: userData.name, // use human-readable names
    })
  }

  // Mixpanel Secondary
  if (config.MIXPANEL_SECONDARY) {
    mixpanel.mixpanel_secondary.identify(userData.agentId)
    mixpanel.mixpanel_secondary.people.set({
      USER_ID: userData.agentId,
      $email: userData.email, // only reserved properties need the $
      $name: userData.name, // use human-readable names
    })
  }

  if (window?.userpilot) {
    window?.userpilot.identify(
      userData.agentId, // Used to identify users
      {
        name: userData.name, // Full name
        email: userData.email, // Email address
        created_at: '1519205055', // Signup date as a Unix timestamp
      }
    )
  }
}

export default setTrackingProfile
