import compareVersions from 'compare-versions'
import localforage from 'localforage'
import { v4 as uuidv4 } from 'uuid'

import config from 'lib/config'
import { dateRangeDropdownOptions, dateRangeValues } from 'lib/dates'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import { uniqBy } from 'lib/utils/array'
import { deepClone } from 'lib/utils/object'
import { mapCustomerMessages } from 'modules/Chat/store/mapper'
import initialState from 'modules/Service/store/initialState'

import { generateTemplateForm } from '../TemplateForm/generateTemplateForm'
import { chatPeekHeader, storeTagsMapper, timezoneMapper } from './mapper'

const actions = {
  toast:
    (message, options = {}) =>
    ({ getState, setState }) => {
      if (!message) {
        return
      }
      const id = options?.id || uuidv4()
      const hideToast = () => {
        let { toast } = getState()
        toast = toast?.filter((toa) => toa.id !== id)
        setState({
          toast: toast?.map((t) => t),
        })
      }
      if (options.duration) {
        options.duration = options.duration * 1000
      } else {
        options.duration = 3000
      }

      const toastPayload = { ...options, id, message }
      const newToast = { ...toastPayload, onClose: hideToast }
      const { toast } = getState()
      const existing = toast.findIndex(({ id: _id }) => _id === id)
      if (!options.button && !options.persist) {
        // removed existing toast timer based on id
        if (existing !== -1 && toast?.[existing]?.timer) {
          window.clearTimeout(toast?.[existing]?.timer)
        }
        newToast.timer = setTimeout(() => {
          hideToast()
        }, options.duration)
      }
      let updatedToast = []
      if (existing !== -1) {
        toast[existing] = newToast
        updatedToast = [...toast.map((t) => t)]
      } else {
        updatedToast = [...getState().toast.map((t) => t), newToast]
      }
      setState({
        toast: updatedToast,
      })
    },
  hideToastById:
    (id) =>
    ({ setState, getState }) => {
      const { toast } = getState()
      setState({
        toast: [...toast.filter(({ id: _id }) => _id !== id)],
      })
    },
  preview:
    (url, options) =>
    ({ setState }) => {
      if (!url) {
        options = {}
      }
      setState({
        preview: {
          url,
          options,
          currentIndex: 0,
        },
      })
    },
  playAudio:
    (audioUrl) =>
    ({ setState }) => {
      setState({ audioUrl })
    },
  fetchAgentsAndTeams:
    ({ headers, agentId, hasLimitedAccess, allTeams = false }) =>
    async ({ setState }) => {
      // allTeams - to get all teams including unvailable teams.
      try {
        const updatedState = {}
        const endPoint = `${config.BASE_API}/members`

        const resp = await fetcher(endPoint, {
          headers,
          params: { all_teams: allTeams ? 'True' : 'False' },
        })
        const { agents, teams } = resp?.data

        updatedState.agentsList = agents.map((agent) => ({
          ...agent,
          type: 'agent',
        }))
        updatedState.teamsList = teams.map((team) => ({
          ...team,
          type: 'team',
          ...(allTeams ? { available: team.active } : { available: true }),
        }))

        if (hasLimitedAccess && agentId) {
          const resp2 = await fetcher(
            `${config.BASE_API}/agent/${agentId}/teams`,
            { headers }
          )
          const agentTeamsIds = resp2?.data?.map(({ id }) => id)

          updatedState.teamsList = updatedState.teamsList.filter(({ id }) =>
            agentTeamsIds?.includes(id)
          )
          updatedState.agentsList = updatedState.agentsList.filter(
            ({ id }) => id === agentId
          )
        }

        setState(updatedState)
      } catch (error) {
        logError(error, 'fetchAgentsAndTeams')
      }
    },
  togglePaymentBanner:
    (show) =>
    ({ setState }) => {
      setState({ showPaymentBanner: show })
    },
  selectSearchedChat:
    ({ conversation, headers }) =>
    async ({ setState, dispatch }) => {
      const { id, lastMessageAt, customerId } = conversation
      await setState({
        chatPeekSearchedMessage: {
          id: id,
          timestamp: lastMessageAt,
          show: true,
        },
      })
      dispatch(
        actions.fetchChatPeekMessages({
          headers,
          customerId,
          direction: 'both',
        })
      )
    },
  resetSearchedMessageShow:
    () =>
    ({ setState, getState }) =>
      setState({
        chatPeekSearchedMessage: {
          ...getState().chatPeekSearchedMessage,
          show: initialState.chatPeekSearchedMessage.show,
        },
      }),
  resetChatPeekMessages:
    (isSearchedMessage = false) =>
    ({ setState, getState }) => {
      setState({
        chatPeekMessages: initialState.chatPeekMessages,
        chatPeekNav: initialState.chatPeekNav,
        prevLastMessageId: initialState.prevLastMessageId,
        chatPeekSearchedMessage: isSearchedMessage
          ? getState()?.chatPeekSearchedMessage
          : initialState.chatPeekSearchedMessage,
      })
    },
  fetchChatPeekMessages:
    ({ headers, customerId: chatPeekCustomerId, direction = '' }) =>
    async ({ setState, getState, dispatch }) => {
      setState({
        chatPeekMessageLoader: true,
        chatPeekCustomerId,
      })
      if (!chatPeekCustomerId) {
        return dispatch(actions.resetChatPeekMessages())
      }
      if (!direction || direction === 'both') {
        await dispatch(actions.resetChatPeekMessages(direction === 'both'))
      }
      if (direction && direction !== 'both') {
        setState({
          chatPeekSearchedMessage: {
            ...getState()?.chatPeekSearchedMessage,
            show: false,
          },
        })
      }
      try {
        const { chatPeekMessages, chatPeekNav, chatPeekSearchedMessage } =
          getState()
        const { id: searchedMessageId, timestamp: searchedMessageTime } =
          chatPeekSearchedMessage

        const defaultEndpoint = `${config.BASE_API}/customer/${chatPeekCustomerId}/messages`

        const nextEndpoint =
          direction === 'up' ? chatPeekNav?.prev : chatPeekNav?.next

        let endPoint,
          params = {}

        if (nextEndpoint) {
          endPoint = nextEndpoint
        } else {
          endPoint = defaultEndpoint
          if (searchedMessageId) {
            params = {
              direction,
              timestamp: searchedMessageTime,
            }
          }
        }
        const customerMessagesResponse = await fetcher(endPoint, {
          headers,
          params,
        })
        const {
          messages = [],
          page_links: { next, prev },
        } = customerMessagesResponse.data

        if (getState().chatPeekCustomerId === chatPeekCustomerId) {
          if (direction === 'down') {
            const _chatPeekMessages = uniqBy(
              [...mapCustomerMessages(messages), ...chatPeekMessages],
              ({ messageId }) => messageId
            )
            setState({
              prevLastMessageId: chatPeekMessages?.[0]?.messageId,
              chatPeekMessages: [..._chatPeekMessages],
              chatPeekNav: {
                ...chatPeekNav,
                prev:
                  chatPeekNav.prev || chatPeekNav.prev === ''
                    ? chatPeekNav.prev
                    : prev,
                next,
              },
            })
          } else {
            const _chatPeekMessages = uniqBy(
              [...chatPeekMessages, ...mapCustomerMessages(messages)],
              ({ messageId }) => messageId
            )
            setState({
              prevLastMessageId: initialState.prevLastMessageId,
              chatPeekMessages: [..._chatPeekMessages],
              chatPeekNav: {
                ...chatPeekNav,
                next:
                  chatPeekNav.next || chatPeekNav.next === ''
                    ? chatPeekNav.next
                    : next,
                prev,
              },
            })
          }
        }
      } catch (error) {
        logError(error, 'fetchCustomerMessages')
        window.globals.service.toast('Failed to load messages', {
          type: 'error',
        })
      } finally {
        setState({
          chatPeekMessageLoader: false,
        })
      }
    },
  fetchPeekHeader:
    ({ customerId, headers }) =>
    async ({ setState }) => {
      try {
        setState({
          chatPeekHeaderLoader: true,
        })
        const resp = await fetcher(
          `${config.BASE_API}/customer/${customerId}`,
          { headers }
        )
        if (resp?.data) {
          const {
            chatPeekHeaderName,
            chatPeekHeaderPhone,
            chatPeekHeaderTags,
            chatPeekHeaderChannel,
          } = chatPeekHeader(resp?.data)
          setState({
            chatPeekHeaderName,
            chatPeekHeaderPhone,
            chatPeekHeaderTags,
            chatPeekHeaderChannel,
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError('fetchPeekHeader', e)
      } finally {
        setState({
          chatPeekHeaderLoader: false,
        })
      }
    },
  onTabChange:
    (value) =>
    ({ setState }) =>
      setState({
        activeTab: value,
      }),
  toggleAdvancedTemplateOptions:
    () =>
    ({ getState, setState }) =>
      setState({
        showAdvancedTemplateOptions: !getState()?.showAdvancedTemplateOptions,
      }),
  updateTemplateField:
    (value) =>
    ({ setState, getState, dispatch }) => {
      const { position } = value
      const templateForm = getState().templateForm
      templateForm[position] = { ...templateForm[position], ...value }
      setState({
        templateForm: [...templateForm],
      })
      dispatch(actions.checkTemplateFormValidated())
    },
  checkTemplateFormValidated:
    () =>
    ({ getState, setState }) => {
      const flag = getState()?.templateForm?.find(
        (item) => item?.value?.trim() === ''
      )

      setState({
        templateFormValidated: Boolean(!flag),
      })
    },
  setTemplateForm:
    (components, buttonParamsCount, templateArgs = [], filename = '') =>
    ({ setState, dispatch }) => {
      let _templateForm = generateTemplateForm(components, buttonParamsCount)
      if (templateArgs?.length) {
        _templateForm = _templateForm?.map((component, index) => {
          if (component.type === 'HEADER') {
            component.filename = filename
          }
          if (templateArgs?.[index]) {
            component.value = templateArgs?.[index]
          }
          return component
        })
      }
      setState({
        templateForm: _templateForm,
      })
      dispatch(actions.checkTemplateFormValidated())
    },
  resetTemplateForm:
    () =>
    ({ setState }) => {
      setState({
        templateForm: initialState?.templateForm,
        templateFormValidated: initialState?.templateFormValidated,
        showAdvancedTemplateOptions: initialState?.showAdvancedTemplateOptions,
      })
    },
  updateHeaderTagsVisibility:
    (showAllHeaderTags) =>
    ({ setState }) =>
      setState({
        showAllHeaderTags,
      }),
  setAnnouncement:
    (showAnnouncement) =>
    ({ setState }) =>
      setState({ showAnnouncement }),
  fetchTimezones:
    ({ headers }) =>
    async ({ setState }) => {
      try {
        const resp = await fetcher(`${config.BASE_API}/timezones`, {
          headers,
          shouldCache: true,
        })
        if (resp?.data) {
          setState({
            timezoneList: timezoneMapper(resp?.data),
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (err) {
        logError(err, 'fetchTimezones')
        window?.globals?.service.toast(
          'settingsPage.businessHoursPage.toast.fetchTimezoneListError',
          {
            type: 'error',
          }
        )
      }
    },
  setToolTipData:
    ({ toolTipPosition, toolTipType, toolTipOffset, toolTipText }) =>
    ({ setState }) =>
      setState({
        toolTipPosition,
        toolTipType,
        toolTipText,
        toolTipOffset,
      }),
  setToolTipVisibility:
    (showTooltip) =>
    ({ getState, setState }) =>
      setState({
        showTooltip,
        toolTipText: !showTooltip ? '' : getState().toolTipText,
      }),
  setToolTipText:
    (toolTipText) =>
    ({ setState }) =>
      setState({
        toolTipText,
      }),
  fetchAddTagsList:
    ({ headers, source = '' }) =>
    async ({ setState }) => {
      setState({
        addTagsLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/store/tags`, {
          headers,
          params: {
            source,
            entity: 'customer',
          },
        })

        if (Array.isArray(resp?.data)) {
          setState({
            addTagsList: storeTagsMapper(resp?.data),
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (err) {
        logError(err, 'getStoreTags')
      } finally {
        setState({
          addTagsLoader: false,
        })
      }
    },
  toggleDateRangePicker:
    () =>
    ({ setState, getState }) => {
      setState({
        showDateRangePicker: !getState()?.showDateRangePicker,
      })
    },
  setRangeFilterPreset:
    (dateFilter, isDefault = false) =>
    ({ setState }) => {
      const _dateFilter = deepClone(dateFilter)
      const _rangeFilterPreset = {
        timezone: _dateFilter?.timezone,
        timeFrom: _dateFilter?.timeFrom,
        timeTo: _dateFilter?.timeTo,
        dateFrom: new Date(_dateFilter?.dateFrom),
        dateTo: new Date(_dateFilter?.dateTo),
      }
      const customPreset = dateRangeDropdownOptions?.find(
        ({ value }) => value === dateRangeValues.CUSTOM
      )
      setState({
        rangeFilterPreset: isDefault
          ? {
              ..._rangeFilterPreset,
              dateFrom: customPreset?.dateFrom(),
              dateTo: customPreset?.dateTo(),
            }
          : { ..._rangeFilterPreset },
      })
    },
  updateRangeFilterPreset:
    (key, value) =>
    ({ setState, getState }) => {
      setState({
        rangeFilterPreset: {
          ...getState()?.rangeFilterPreset,
          [key]: value,
        },
      })
    },
  changePreviewPosition:
    (currentIndex) =>
    ({ getState, setState }) =>
      setState({
        preview: {
          ...getState()?.preview,
          currentIndex,
        },
      }),
  updateConfirmationLoader:
    (confirmationLoader) =>
    ({ setState }) => {
      setState({
        confirmationLoader,
      })
    },
  confirmation:
    (onConfirm, options = {}) =>
    ({ setState, getState }) => {
      window.globals.onConfirm = onConfirm
      setState({
        openConfirmation: true,
        confirmation: {
          ...getState()?.confirmation,
          ...options,
        },
      })
    },
  resetConfirmation:
    () =>
    ({ setState }) => {
      window.globals.onConfirm = null
      setState({
        openConfirmation: initialState.openConfirmation,
        confirmation: initialState.confirmation,
        confirmationLoader: initialState.confirmationLoader,
      })
    },
  onCursorPosChange:
    (cursorPos) =>
    ({ setState }) => {
      setState({
        cursorPos,
      })
    },
  appVersionCheck:
    () =>
    async ({ setState }) => {
      window.console.log('appVersionCheck')
      const storageKey = 'zoko_last_update_check_at'
      const lastCheckAt = await localforage?.getItem(storageKey)
      if (lastCheckAt) {
        const diff = Date.now() - Number(lastCheckAt)
        const timer = config.ENV !== 'production' ? 30 * 1000 : 10 * 60 * 1000 // 10 mins
        if (diff < timer) {
          return
        }
      }
      localforage?.setItem(storageKey, Date.now())
      setState({
        appVersionLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/version`)
        if (resp?.data) {
          setState({
            appVersion: resp?.data,
          })
          window.console.log('VERSION', resp?.data)
          const platform = resp?.data?.find(
            ({ platform }) => platform === 'web'
          )
          const versionMatch = compareVersions(
            platform?.version,
            window?.globals?.version?.replace('v', '')
          )
          if (versionMatch === 1) {
            window?.globals?.service?.toast(
              'New version available: v' + platform?.version,
              {
                id: 'appversion',
                button: {
                  label: 'REFRESH',
                  onClick: () => window?.location?.reload(),
                },
              }
            )
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'appVersion')
        window?.globals?.service?.toast('Failed to load version', {
          type: 'error',
        })
      } finally {
        setState({
          appVersionLoader: false,
        })
      }
    },
}
export default actions
