const firebaseConfig = {
  apiKey: 'AIzaSyDYW5jlZHM07EkMF_1K7H3ewncKaRB2Mgs',
  authDomain: 'zoko-web-prod.firebaseapp.com',
  databaseURL: 'https://zoko-web-prod.firebaseio.com',
  projectId: 'zoko-web-prod',
  storageBucket: 'zoko-web-prod.appspot.com',
  messagingSenderId: '30512584928',
  appId: '1:30512584928:web:92b38083f30e4155',
  FCMWebPushCertificate:
    'BOTx4PCuKBE_FUzml9J6ku--G17GSWzoX8thY17FHPnD9FCEyi4HxFRPmIpr0pZGDgIHeE9JVSrkcriQJFHDOHI',
}

exports.firebaseConfig = firebaseConfig
