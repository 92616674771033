import React, { useEffect } from 'react'

import { Loading } from 'common/UI/Loading'
import config from 'lib/config'

import styles from './ShopifyInstall.module.scss'

const ShopifyInstall = () => {
  useEffect(() => {
    window.location.href = `${
      config.BASE_API
    }/integrations/shopify/public/install?${window.location.href.split('?')[1]}`
  }, [])
  return (
    <div className={styles.shopifyInstallWrapper}>
      <Loading />
    </div>
  )
}

export default ShopifyInstall
