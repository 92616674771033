import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import config from 'lib/config'
import { useStoreList } from 'modules/Auth/store'

import { _userPilotTracking as userPilotTracking } from './UserPilotTracking'

const UserPilot = () => {
  const [{ currentStoreId }] = useStoreList()
  useEffect(() => {
    config.USER_PILOT &&
      currentStoreId &&
      userPilotTracking('store_id', { id: currentStoreId })
  }, [currentStoreId])

  if (!config.USER_PILOT) return null
  return (
    <Helmet>
      <script>{`window.userpilotSettings = {token: "${config.USER_PILOT}"}`}</script>
      <script src="https://js.userpilot.io/sdk/latest.js" />
    </Helmet>
  )
}

export default UserPilot

export { userPilotTracking }
