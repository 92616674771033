import routes from 'lib/config/routes'

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  window.navigator.userAgent
)

export const isMac = () => window?.navigator?.userAgent?.indexOf('Mac') != -1

export const openInNewTab = (url) => {
  window.open(url, '_blank')
}

export const landingPageRoute = ({ history, isMobile }) => {
  if (isMobile) {
    history.replace(routes.INITIAL)
  } else {
    history.replace(routes.OVERVIEW)
  }
}

export const redirectBoth = ({ redirectUri, history }) => {
  if (
    redirectUri.includes('http://') ||
    redirectUri.includes('https://') ||
    redirectUri.includes('www.')
  ) {
    window.location.href = redirectUri
  } else {
    history.push(redirectUri)
  }
}
