import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { Button } from 'common/UI/Button'
import { Empty } from 'common/UI/Empty'
import { Icon, icons } from 'common/UI/Icon'
import { Text } from 'common/UI/Text'
import roleMatrix, { modules } from 'lib/config/roleMatrix'
import routes from 'lib/config/routes'
import i18n from 'lib/i18n'
import { redirectBoth } from 'lib/utils/platform'

import { useHostInfo, useRole } from '../store'
import styles from './AccessCheck.module.scss'

const accessCheck = (role, moduleId) => {
  const currentAccess = roleMatrix[role]
  return currentAccess && currentAccess.includes(moduleId)
}

const AccessCheck = ({
  moduleId,
  children,
  hideError = false,
  redirectUri,
  fallbackComponent,
  className,
  hideInWhiteLabel = false,
}) => {
  const [role] = useRole()
  const accessGranted = accessCheck(role, moduleId)
  const history = useHistory()
  const [hostInfo] = useHostInfo()

  const isWhiteLabelled = !hostInfo?.showBilling
  const showPageNotFound = isWhiteLabelled && hideInWhiteLabel

  useEffect(() => {
    if (moduleId && redirectUri && !accessGranted && !showPageNotFound) {
      window.setTimeout(() => {
        redirectBoth({ redirectUri, history })
      }, 2000)
    }
  }, [moduleId, redirectUri, accessGranted, history, showPageNotFound])

  if (showPageNotFound) {
    return (
      <div className={styles.emptyWrapper}>
        <Empty iconSize={'var(--p224)'}>
          <div className={styles.pageNotFound}>
            <Text
              translate
              fontWeight={700}
              fontSize={Text.fontSize.p36}
              className={styles.title}
            >
              hideInWhiteLabelError.title
            </Text>
            <Text
              translate
              fontWeight={400}
              fontSize={Text.fontSize.p16}
              className={styles.description}
            >
              hideInWhiteLabelError.description
            </Text>
            <Link to={routes.INITIAL}>
              <Button>{i18n.t('hideInWhiteLabelError.backHomeBtn')}</Button>
            </Link>
          </div>
        </Empty>
      </div>
    )
  }

  if (!moduleId || !accessGranted) {
    if (hideError) {
      return null
    }
    if (fallbackComponent) {
      return fallbackComponent
    }
    let description =
      'You are not authorized to view this page. Please contact the owner.'
    if (redirectUri) {
      description = 'You are not authorized to view this page. Redirecting now.'
    }
    const accessClass = classNames(styles.accessCheckWrapper, {
      [className || '']: className,
    })
    return (
      <div className={accessClass}>
        <Icon source={icons.somethingWentWrong} size="var(--p80)" />
        {description}
      </div>
    )
  }
  return children
}

AccessCheck.modules = modules

AccessCheck.propTypes = {
  moduleId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideError: PropTypes.bool,
  hideInWhiteLabel: PropTypes.bool,
  redirectUri: PropTypes.string,
  fallbackComponent: PropTypes.node,
  className: PropTypes.string,
}

export { AccessCheck, accessCheck }
