import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth'
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from 'firebase/messaging'

import config from 'lib/config'
import logError from 'lib/logError'
import trackEvent from 'lib/trackEvent'

const firebaseApp = initializeApp(config.FIREBASE)

/* START: FIREBASE AUTH */
const googleAuthProvider = new GoogleAuthProvider()
export const firebaseAuth = getAuth(firebaseApp)

export const signInWithEmailPassword = async (email, password, cb) => {
  await signInWithEmailAndPassword(firebaseAuth, email, password)
    .then((result) => {
      cb?.(result)
    })
    .catch((error) => {
      showAuthError(error)
      logError(error, 'signInWithEmailPassword')
    })
}
export const signInWithGoogle = (cb) => {
  signInWithPopup(firebaseAuth, googleAuthProvider)
    .then((result) => {
      cb?.(result)
    })
    .catch((error) => {
      window?.globals?.service?.toast(
        'signInWithGoogle:' + JSON.stringify(error)
      )
      window.console.error('signInWithGoogle:', error)
      logError(error, 'signInWithGoogle')
    })
}
export const logout = () => {
  signOut(firebaseAuth)
    .then(() => {
      window.console.log('Logged Out Successfully')
      trackEvent(trackEvent.modules.secondaryAccount, trackEvent.events.log_out)
    })
    .catch((error) => {
      logError(error, 'logout')
    })
}
export const getAuthToken = async () => {
  try {
    const token = await firebaseAuth?.currentUser?.getIdToken()
    return token
  } catch (error) {
    logError(error, 'getAuthToken')
    return ''
  }
}
export const resetPasswordRequest = async (email, cb) => {
  await sendPasswordResetEmail(firebaseAuth, email)
    .then(() => {
      cb?.()
    })
    .catch((error) => {
      showAuthError(error)
      logError(error, 'resetPasswordRequest')
    })
}

export const signUpWithEmailPassword = async (email, password, cb) => {
  await createUserWithEmailAndPassword(firebaseAuth, email, password)
    .then((result) => {
      cb?.(result)
    })
    .catch((error) => {
      showAuthError(error)
      logError(error, 'signInWithEmailPassword')
    })
}
/* END: FIREBASE AUTH */

/* START: FCM */

export const getFCMToken = async () => {
  const firebaseMessaging = getMessaging(firebaseApp)
  try {
    const token = await getToken(firebaseMessaging, {
      vapidKey: config.FIREBASE.FCMWebPushCertificate,
    })
    window.console.log('getFCMToken', 'FCM: current token for client: ', token)
    onMessage(firebaseMessaging, (payload) =>
      window.console.log('getFCMToken', 'FCM:', payload)
    )
    return token
  } catch (err) {
    window.console.log(
      'getFCMToken',
      'FCM: An error occurred while retrieving token.',
      err
    )
  }
}

export const deleteFCMToken = async () => {
  const firebaseMessaging = getMessaging(firebaseApp)
  try {
    await deleteToken(firebaseMessaging)
  } catch (err) {
    window.console.log(
      'deleteFCMToken',
      'FCM: An error occurred while deleting token.',
      err
    )
  }
}

const showAuthError = (error) => {
  const codeMap = {
    'auth/user-not-found':
      'There is no user record corresponding to this identifier. The user may have been deleted.',
    'auth/wrong-password':
      'The password is invalid or the user does not have a password.',
    'auth/invalid-email': 'The email address is badly formatted.',
    'auth/email-already-in-use':
      'The email address is already in use by another account.',
    'auth/weak-password': 'Password should be at least 6 characters',
  }
  const message = codeMap?.[error?.code] || error?.message || 'Unknown error'
  window?.globals?.service?.toast(message, { type: 'error' })
}

/* END: FCM */
