import React from 'react'
import { Helmet } from 'react-helmet-async'

import config from 'lib/config'

const UserPilotFix = () => {
  if (config.ENV !== 'production') {
    return (
      <>
        <Helmet>
          <style>
            {`body > iframe {
                display:none;
            }`}
          </style>
        </Helmet>
      </>
    )
  }
  return null
}

export default UserPilotFix
