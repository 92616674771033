const Images = {
  chatEmpty: require('./files/chat-empty.svg').default,
  chatPreviewEmpty: require('./files/chatPreviewEmpty.svg').default,
  billing: require('./files/billing.png').default,
  pricing: require('./files/pricing.png').default,
  scheduled: require('./files/scheduled.svg').default,
  loginBg: require('./files/login-bg.jpg').default,
  broadcastImport: require('./files/broadcast-import.png').default,
  broadCastTags: require('./files/broadcast-tags.png').default,
  templateIntro: require('./files/template_intro.png').default,
  templateCreate: require('./files/template_create.png').default,
  announcement: require('./files/announcement.jpg').default,
  announcementHorn: require('./files/announcement_horn.png').default,
  announcementCircle: require('./files/announcement_circle.png').default,
  rbiAnnounce: require('./files/rbi_announcement.png').default,
  broadcastTier: require('./files/broadcast_tier.png').default,
  announcementSpeaker: require('./files/announcement_speaker.svg').default,
  paperPlane: require('./files/paper-plane.svg').default,
  rings: require('./files/rings.svg').default,
  announcementBg: require('./files/announcement-bg.png').default,
  alertBg: require('./files/alert-bg.png').default,
  createArule: require('./files/create_a_rule.png').default,
  autoDistribute: require('./files/auto_distribute.png').default,
  createSegment: require('./files/createSegment.png').default,
  segmentUsage: require('./files/segment_usage.png').default,
  poweredByStripe: require('./files/stripe-powered.png').default,
  coinsGrey: require('./files/coins-grey.png').default,
  summer: require('./files/summer.png').default,
  syncNews: require('./files/syncNews.jpg').default,
  billingAlert: require('./files/billing_alert.png').default,
  broadCastAnnounce: require('./files/broadcast_announce.jpg').default,
}

export default Images
