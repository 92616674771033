const handleAssignmentEvent = ({ payload, stateObject }) => {
  const updatedState = {} // This will hold the final updated state

  const { setState, getState } = stateObject
  const { queueList, queuePagination } = getState()
  const { totalItems } = queuePagination
  const { customer } = payload

  /* For all assignment events ['closed', 'assigned'], 
    we have to remove the item from the list if it already exists. */

  // Remove the item from updatedQList if it already exists
  updatedState.queueList = queueList.filter(
    ({ customerId }) => customerId !== customer.id
  )
  if (updatedState.queueList.length < queueList.length) {
    // Update total items count
    updatedState.queuePagination = {
      ...queuePagination,
      totalItems: totalItems - 1,
    }
  }

  setState(updatedState)
}

export default handleAssignmentEvent
