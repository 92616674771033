export const getParams = (key, url = window.location.search) => {
  if (url.includes('?')) {
    url = `?${url.split('?')?.[1]}`
  }
  const query = new URLSearchParams(url)
  return key ? query.get(key) : query
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
