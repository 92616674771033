export const userDataMapper = (userData = {}) => ({
  email: userData?.email,
  agentId: userData?.id,
  name: userData?.name,
  image: userData?.image,
})

export const headerMapper = ({ storeId }) => {
  let headers = {
    includeAuthToken: true, // this will be replaced with latest x-auth-token by the fetcter in lib/
  }
  if (storeId) {
    headers['x-store-id'] = storeId
  }
  return headers
}

export const hostInfoMapper = (hostInfo) => {
  return {
    domain: hostInfo?.domain,
    active: hostInfo?.active,
    logoUrl: hostInfo?.logo_url,
    termsUrl: hostInfo?.terms_url,
    policyUrl: hostInfo?.policy_url,
    pageTitle: hostInfo?.page_title,
    faviconUrl: hostInfo?.favicon_url,
    showBilling: hostInfo?.show_billing,
    settingsUrl: hostInfo?.settings_url,
    phoneLogoUrl: hostInfo?.phone_logo_url,
    loginPageGreeting: hostInfo?.login_page_greeting,
    loginPageImage: hostInfo?.login_page_image,
    links: hostInfo?.links,
  }
}
