import React from 'react'
import { createRoot } from 'react-dom/client'
import './lib/i18n/index'
import { HelmetProvider } from 'react-helmet-async'

import { ErrorBoundary } from 'common/UI/ErrorBoundary'
import { ThemeProvider } from 'common/contexts/themeContext/themeContext'
import initialize from 'lib/config/intializations'
import { useHostInfo } from 'modules/Auth/store'

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)

const ErrorCatcher = () => {
  const [hostInfo] = useHostInfo()
  return (
    <ErrorBoundary
      componentName="MainApp"
      isWhiteLabelled={!hostInfo?.showBilling}
    >
      <React.StrictMode>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </React.StrictMode>
    </ErrorBoundary>
  )
}

root.render(
  <ThemeProvider>
    <ErrorCatcher />
  </ThemeProvider>
)

initialize()
