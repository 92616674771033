import React from 'react'

import { Icon, icons } from 'common/UI/Icon'
import logError from 'lib/logError'

import AudioService from './AudioService'
import LogData from './LogData'
import Preview from './Preview'
import styles from './Service.module.scss'
import ServiceConfirmation from './ServiceConfirmation'
import ServiceTooltip from './ServiceTooltip'
import Toast from './Toast'
import UserPilot from './UserPilot'
import UserPilotFix from './UserPilotFix'

class Service extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, hideWarning: false }
  }
  componentDidCatch(error) {
    logError(error, 'APP_CRASH', {
      componentName: 'Services',
      event: error,
    })
    this.setState({
      hasError: true,
    })
  }
  render() {
    if (this.state.hideWarning) {
      return null
    }
    if (this.state.hasError) {
      return (
        <div
          className={styles.errorMessage}
          role="presentation"
          onClick={() => {
            this.setState({
              hideWarning: true,
            })
          }}
        >
          <div className={styles.content}>
            ERROR: All services are offline
            <Icon source={icons.close} size="var(--p12)" />
          </div>
        </div>
      )
    }
    return (
      <>
        <ServiceTooltip />
        <Toast />
        <Preview />
        <AudioService />
        <UserPilot />
        <ServiceConfirmation />
        <UserPilotFix />
        <LogData />
      </>
    )
  }
}

export default Service
