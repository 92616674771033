const _throttler = () => {
  let throttleTimer = null

  return (func, wait = 600) => {
    if (throttleTimer) {
      return
    }

    if (func) func()

    throttleTimer = setTimeout(() => {
      throttleTimer = null
    }, wait)
  }
}

const throttler = _throttler()

export { throttler }
