export const dateSortOptions = {
  asc: 'asc',
  desc: 'desc',
}

export default {
  dateSort: dateSortOptions.desc,
  queueList: [],
  lastSelectedId: null,
  queuePagination: {
    currentPageNo: null,
    pageSize: null,
    totalItems: 0,
    totalPages: null,
    nextPageUrl: null,
  },
  queueListLoader: true,
  queueOffline: false,
  invertSelection: false, // If this is true => the selected items are treated as exception list and if false => selected items are treated as included list
}
