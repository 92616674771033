import config from 'lib/config'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import { uniqBy } from 'lib/utils/array'
import { deepClone } from 'lib/utils/object'
import { templateVariableRegex } from 'lib/utils/regexManager'

import { conversationTabs } from '../constants'
import { manageChatApi } from './helpers/manageChat'
import realtimeHandler from './helpers/realtimeHandler'
import { sendChatMessage, sendMessage } from './helpers/sendChatMessage'
import initialState from './initialState'
import {
  mapConversations,
  mapCustomerMessages,
  mapPagination,
  selectedConversationMapper,
} from './mapper'

const actions = {
  fetchConversations:
    ({ headers }) =>
    async ({ getState, setState, dispatch }) => {
      const { viewAsId, conversationsPagination, conversationTab } = getState()

      try {
        setState({
          loadingConversations: true,
        })
        const { nextPageUrl } = conversationsPagination

        if (!viewAsId) return // If viewAsId === null then stop

        if (!nextPageUrl) {
          // => Loading for first time || page = 1
          // Therefore reset conversations
          dispatch(actions.resetConversations())
        }

        const endPoint =
          nextPageUrl ||
          `${config.BASE_API}/agent/${viewAsId}/conversations?page=1&sort=desc&filter=${conversationTab}`
        const conversationsResponse = await fetcher(endPoint, {
          headers,
        })

        if (
          !conversationsResponse?.data?.error &&
          conversationsResponse?.data?.results
        ) {
          // Only update the list if the tab wasn't switched in between
          if (conversationTab === getState()?.conversationTab) {
            const {
              conversations = [],
              total_unread: totalUnread,
              total_unresponded: totalUnresponded,
            } = conversationsResponse?.data?.results
            const conversationsList = nextPageUrl
              ? [
                  ...getState()?.conversationsList,
                  ...mapConversations(conversations),
                ]
              : mapConversations(conversations)
            setState({
              conversationsPagination: mapPagination(
                conversationsResponse?.data?.pagination
              ),
              conversationsList: uniqBy(
                conversationsList,
                ({ customerId }) => customerId
              ),
              totalUnread,
              totalUnresponded,
            })
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'fetchConversations')
        window.globals.service.toast('Failed to load conversations', {
          type: 'error',
        })
      } finally {
        if (conversationTab === getState().conversationTab) {
          // Only update the state if the tab wasn't switched switched in between
          setState({
            loadingConversations: false,
          })
        }
      }
    },
  fetchCustomerMessages:
    ({ headers, customerId, direction = 'up', getLatest }) =>
    async ({ setState, getState, dispatch }) => {
      try {
        setState({
          loadingMessages: true,
        })
        const { chatMessages, chatMessageNavigation, searchedMessage } =
          getState()
        const { id: searchedMessageId, timestamp: searchedMessageTime } =
          searchedMessage
        const defaultEndpoint = `${config.BASE_API}/customer/${customerId}/messages`

        const nextEndpoint =
          direction === 'up'
            ? chatMessageNavigation?.prev
            : chatMessageNavigation?.next

        let endPoint,
          params = {}

        if (getLatest) {
          endPoint = defaultEndpoint
          params = {
            direction: 'next',
            all: true,
            timestamp: chatMessages?.[0]?.timestamp || 0,
          }
        } else if (nextEndpoint) {
          endPoint = nextEndpoint
        } else {
          endPoint = defaultEndpoint
          if (searchedMessageId) {
            params = {
              direction: 'both',
              timestamp: searchedMessageTime,
            }
          }
        }

        const customerMessagesResponse = await fetcher(endPoint, {
          headers,
          params,
        })
        if (
          customerMessagesResponse?.data?.messages &&
          !customerMessagesResponse?.data?.error
        ) {
          const { messages = [], page_links: { next, prev } = {} } =
            customerMessagesResponse.data

          if (getState().selectedConversation?.customerId === customerId) {
            if (direction === 'up') {
              const _chatMessages = uniqBy(
                [...chatMessages, ...mapCustomerMessages(messages)],
                ({ messageId }) => messageId
              )

              setState({
                prevLastMessageId: initialState.prevLastMessageId,
                loadingMessages: false,
                chatMessages: _chatMessages,
                chatMessageNavigation: {
                  ...chatMessageNavigation,
                  next:
                    chatMessageNavigation.next ||
                    chatMessageNavigation.next === ''
                      ? chatMessageNavigation.next
                      : next,
                  prev: prev,
                },
              })
            } else if (direction === 'down') {
              const _chatMessages = uniqBy(
                [...mapCustomerMessages(messages), ...chatMessages],
                ({ messageId }) => messageId
              )

              setState({
                prevLastMessageId: chatMessages?.[0]?.messageId,
                loadingMessages: false,
                chatMessages: _chatMessages,
                chatMessageNavigation: {
                  ...chatMessageNavigation,
                  prev:
                    chatMessageNavigation.prev ||
                    chatMessageNavigation.prev === ''
                      ? chatMessageNavigation.prev
                      : prev,
                  next: next,
                },
              })
            }

            dispatch(actions.updateSelectedConversationData({ headers }))
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'fetchCustomerMessages')
        window.globals.service.toast('Failed to load messages', {
          type: 'error',
        })
      }
    },
  realtimeChatUpdate: (realtimeEvent) => (stateObject) => {
    const { event_name: eventName, payload, headers } = realtimeEvent
    return realtimeHandler({ headers, eventName, payload, stateObject })
  },
  chatBoxSetIsWriting:
    (value) =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          isWriting: value,
        },
      })
    },
  chatBoxSetIsAudioMode:
    (value) =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          isAudioMode: value,
        },
      })
    },
  chatBoxSetCount:
    (value) =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          count: value,
        },
      })
    },
  selectConversation:
    ({
      headers,
      customerId,
      persistSelectedConversation = initialState.persistSelectedConversation,
    }) =>
    async ({ setState, dispatch }) => {
      dispatch(actions.resetSelectedConversation())
      setState({
        selectedConversation: { customerId },
        persistSelectedConversation,
      })
      dispatch(actions.updateSelectedConversationData({ headers }))
    },
  updateSelectedConversationData:
    ({ headers }) =>
    async ({ getState, setState }) => {
      // Get the selected customer id
      const customerId = getState().selectedConversation?.customerId
      const viewAsId = getState().viewAsId
      const dataLoaded = getState().selectedConversation?.dataLoaded
      if (customerId) {
        try {
          !dataLoaded &&
            setState({
              loadingSelectedConversationData: true,
            })
          const resp = await fetcher(
            `${config.BASE_API}/customer/${customerId}`,
            {
              headers,
              params: {
                agent: viewAsId,
              },
            }
          )
          if (resp?.data) {
            const selectedConversation = selectedConversationMapper(resp.data)
            if (
              selectedConversation?.customerId ===
              getState().selectedConversation?.customerId
            ) {
              // Update state only if the latest value of selected customerId is still the same

              const updatedState = {
                selectedConversation: {
                  ...selectedConversation,
                  dataLoaded: true, // [dataLoaded: true] implies that the data was loaded at least once, so next time we won't set loadingSelectedConversationData
                },
              }
              const conversationsList = getState().conversationsList
              updatedState.conversationsList = conversationsList.map((conv) => {
                if (conv.customerId === customerId) {
                  return {
                    ...conv,
                    ...selectedConversation,
                  }
                }
                return conv
              })

              setState(updatedState)
            }
          } else {
            throw new Error('Invalid customer data')
          }
        } catch (error) {
          logError(error, 'fetchCustomerData')
          window.globals.service.toast('Failed to load customer data')
        } finally {
          setState({
            loadingSelectedConversationData: false,
          })
        }
      }
    },
  selectSearchedChat:
    ({ conversation, headers }) =>
    ({ setState, dispatch }) => {
      const { id, customerId, lastMessageAt } = conversation
      dispatch(actions.resetSelectedConversation())
      setState({
        selectedConversation: { customerId },
        searchedMessage: {
          id: id,
          timestamp: lastMessageAt,
          show: true,
        },
      })
      dispatch(actions.updateSelectedConversationData({ headers }))
    },
  writeTextMessage:
    (message) =>
    ({ setState, getState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          message,
        },
      })
    },
  sendChatMessage: sendChatMessage,
  requestMessagingPermission:
    ({ headers }) =>
    async ({ getState, setState }) => {
      const { selectedConversation } = getState()
      const recipient = selectedConversation?.customerId
      const channel = selectedConversation?.channel
      setState({
        requestPermissionLoader: true,
      })
      try {
        const payload = {
          channel,
          private: false,
          recipient,
          type: 'human_tag',
        }
        const res = await sendMessage(headers, payload)
        if (res?.data?.error) {
          throw new Error('Invalid response')
        }
      } catch (e) {
        window?.globals?.service?.toast('sendMessageFailure', {
          type: 'error',
        })
        logError(e, 'requestMessagingPermission')
      } finally {
        setState({
          requestPermissionLoader: false,
        })
      }
    },
  toggleNotesMode:
    () =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          isNotesMode: !getState()?.chatBox?.isNotesMode,
        },
      })
    },
  updateAttachments:
    (attachments) =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          attachments: uniqBy([
            ...getState().chatBox?.attachments,
            ...attachments,
          ]),
        },
      })
    },
  deleteAttachment:
    (index) =>
    ({ getState, setState }) => {
      const attachments = getState().chatBox.attachments
      attachments.splice(index, 1)
      setState({
        chatBox: {
          ...getState().chatBox,
          attachments: [...attachments],
        },
      })
    },
  changeQuickOption:
    (option) =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          selectedQuickOption: option,
        },
      })
    },
  fetchQuickReplies:
    ({ headers, service, page = 1 }) =>
    async ({ getState, setState }) => {
      setState({
        quickRepliesLoader: true,
      })

      if (page === 1) {
        setState({
          quickReplies: [],
          quickRepliesPagination: {},
        })
      }

      const params = {
        page,
        size: 10,
        search: getState()?.quickRepliesSearchText,
      }

      if (getState()?.quickRepliesFilter?.length) {
        params.type = getState()?.quickRepliesFilter?.join(',')
      }

      try {
        const resp = await fetcher(`${config.BASE_API}/quick-replies`, {
          params,
          headers,
        })

        if (resp?.data?.results) {
          setState({
            quickReplies: uniqBy([
              ...(getState()?.quickReplies ?? []),
              ...(resp?.data?.results ?? []),
            ]),
            quickRepliesPagination: resp?.data?.pagination,
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'fetchQuickReplies')
        service.toast('Failed to load quick replies', { type: 'error' })
      } finally {
        setState({
          quickRepliesLoader: false,
        })
      }
    },
  fetchQuickTemplates:
    ({ headers, service }) =>
    async ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          quickTemplatesLoader: true,
        },
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/templates`, {
          headers,
          params: { filter: 'chat' },
        })
        //iterating through templates to make {{1}} these correct
        resp.data?.templates?.forEach((item) => {
          const headerCount = item.header_params_count
          const bodyCount = item.body_params_count

          item?.components?.forEach((component) => {
            if (headerCount && component.type === 'BODY') {
              const _text = component?.text?.replace(
                templateVariableRegex,
                (match, i) => {
                  const count = parseInt(i) + headerCount
                  return `{{${count}}}`
                }
              )
              component.text = _text
            }
            if ((headerCount || bodyCount) && component.type === 'FOOTER') {
              const _text = component?.text?.replace(
                templateVariableRegex,
                (match, i) => {
                  const count = parseInt(i) + headerCount + bodyCount
                  return `{{${count}}}`
                }
              )
              component.text = _text
            }
          })
        })
        setState({
          chatBox: {
            ...getState().chatBox,
            quickTemplates: resp?.data?.templates,
            quickTemplatesLoader: false,
          },
        })
      } catch (error) {
        logError(error, 'fetchQuickTemplates')

        setState({
          chatBox: {
            ...getState().chatBox,
            quickTemplatesLoader: false,
          },
        })
        service.toast('Failed to fetch templates', { type: 'error' })
      }
    },
  showQuickOptions:
    (show) =>
    ({ setState }) => {
      setState({
        showQuickOptions: show,
        quickRepliesFilter: initialState.quickRepliesFilter,
        quickRepliesSearchText: initialState.quickRepliesSearchText,
      })
    },
  editQuickTemplate:
    (show, templateId) =>
    ({ getState, setState }) => {
      setState({
        chatBox: {
          ...getState().chatBox,
          editTemplateId: templateId,
          showEditTemplateModal: show,
        },
      })
    },
  chatSetReadStatus:
    ({ headers, customerId, read, hideToast = false }) =>
    async ({ setState, getState, dispatch }) => {
      try {
        setState({ manageChatLoader: true })
        const resp = await manageChatApi(headers, {
          customerId,
          body: { read },
        })
        const isSuccess = read === resp?.data?.read && resp?.ok

        if (isSuccess) {
          const { conversationsList, selectedConversation, conversationTab } =
            getState()
          const updatedState = {}

          if (conversationTab === conversationTabs.unread.value) {
            if (read) {
              // If conversation tab is "Unread", then remove the item from conversationList
              updatedState.conversationsList = conversationsList.filter(
                (conv) => conv.customerId !== customerId
              )
            } else {
              // TODO: Add item to conversation list if tab is "Unread"
              // Why not done? becuase the entire data is not available (only customerId is available)
              // Actual solution: use mqtt
            }
          } else {
            // Update unreadCount and isUnread of the conversation in conversation list
            updatedState.conversationsList = conversationsList.map((conv) => {
              if (conv.customerId === customerId) {
                conv.unreadCount = 0
                conv.isUnread = !read
              }
              return conv
            })
          }

          if (selectedConversation.customerId === customerId) {
            if (!read) {
              // On "unread", close selected conversation
              dispatch(actions.resetSelectedConversation())
            } else {
              // Update unreadCount and isUnread of the selectedConversation
              dispatch(actions.updateSelectedConversationData({ headers }))
            }
          }

          setState(updatedState)

          if (!hideToast) {
            // Show success toast
            window.globals.service.toast(
              read ? 'markAsReadSuccess' : 'markAsUnreadSuccess',
              { type: 'success' }
            )
          }

          // Update current agent's chats count
          dispatch(actions.fetchCurrentAgentChatsCount({ headers }))
        }
      } catch (error) {
        logError(error, 'chatSetReadStatus')
        window.globals.service.toast(
          read ? 'markAsReadFailed' : 'markAsUnreadFailed',
          { type: 'error' }
        )
      } finally {
        setState({ manageChatLoader: false })
      }
    },
  chatSetPinned:
    ({ headers, customerId, pin, hideToast }) =>
    async ({ setState, getState, dispatch }) => {
      try {
        const { viewAsId } = getState()
        setState({ manageChatLoader: true })
        const resp = await manageChatApi(headers, {
          customerId,
          body: {
            pin,
            pin_agent: viewAsId,
          },
        })
        const isSuccess = pin === resp?.data?.pinned && resp?.ok

        if (isSuccess) {
          const { conversationsList, selectedConversation } = getState()
          const updatedState = {}

          // TODO: Move/Add item to top of conversation list
          // Entire data of customer not available (only customerId available)
          // Actual solution: use mqtt

          // Update pinned status of the conversation in conversation list
          updatedState.conversationsList = conversationsList.map((conv) => {
            if (conv.customerId === customerId) conv.isPinned = pin
            return conv
          })

          if (selectedConversation.customerId === customerId) {
            // Update pinned status of the selectedConversation
            dispatch(actions.updateSelectedConversationData({ headers }))
          }

          setState(updatedState)

          if (!hideToast) {
            // Show success toast
            window.globals.service.toast(
              pin ? 'pinChatSuccess' : 'unPinChatSuccess',
              { type: 'success' }
            )
          }
        }
      } catch (error) {
        logError(error, 'chatSetPinned')
        window.globals.service.toast(
          pin ? 'pinChatFailed' : 'unPinChatFailed',
          { type: 'error' }
        )
      } finally {
        setState({ manageChatLoader: false })
      }
    },
  chatClose:
    ({ headers, customerId, hideToast }) =>
    async ({ setState, getState, dispatch }) => {
      try {
        // This is not good. Ask Abhai to automatically get the logged in agent id
        const { viewAsId } = getState()
        setState({ manageChatLoader: true })
        const resp = await manageChatApi(headers, {
          customerId,
          body: {
            assign_to: viewAsId,
            assign_status: 'closed',
          },
        })
        const isSuccess = resp?.ok
        if (isSuccess) {
          if (!hideToast) {
            // Show success toast
            window.globals.service.toast('closeChatSuccess', {
              type: 'success',
            })
          }

          // Update current agent's chats count
          dispatch(actions.fetchCurrentAgentChatsCount({ headers }))
        }
      } catch (error) {
        logError(error, 'chatClose')
        window.globals.service.toast('closeChatFailed', { type: 'error' })
      } finally {
        setState({ manageChatLoader: false })
      }
    },
  assignChat:
    ({ headers, customerId, assignToId, hideToast }) =>
    async ({ setState, dispatch }) => {
      try {
        setState({ manageChatLoader: true })
        const resp = await manageChatApi(headers, {
          customerId,
          body: {
            assign_to: assignToId,
            assign_status: 'assigned',
          },
        })
        const isSuccess = resp?.ok

        if (isSuccess) {
          if (!hideToast) {
            // Show success toast
            window.globals.service.toast('chatAssignSuccess', {
              type: 'success',
            })
          }

          // Update current agent's chats count
          dispatch(actions.fetchCurrentAgentChatsCount({ headers }))
        }
      } catch (error) {
        logError(error, 'assignChat')
        window.globals.service.toast('chatAssignFailed', { type: 'error' })
      } finally {
        setState({ manageChatLoader: false })
      }
    },
  fetchCurrentAgentChatsCount:
    ({ headers }) =>
    async ({ setState, getState }) => {
      try {
        const { viewAsId } = getState()

        if (viewAsId) {
          const resp = await fetcher(
            `${config.BASE_API}/agent/${viewAsId}/counts`,
            { headers, skipTracking: true }
          )
          const data = resp?.data
          if (resp?.data) {
            const { unread: totalUnread, unresponded: totalUnresponded } = data
            setState({ totalUnread, totalUnresponded })
          } else {
            throw new Error('Invalid response')
          }
        }
      } catch (error) {
        logError(error, 'fetchCurrentAgentChatsCount')
      } finally {
        window?.globals?.service?.appVersionCheck()
      }
    },
  changeSelectedDock:
    (selected, isDefault = false) =>
    ({ getState, setState }) => {
      const { dockTabs } = getState()
      const _selected = dockTabs.selected === selected ? null : selected
      setState({
        dockTabs: {
          ...dockTabs,
          selected: isDefault ? selected : _selected,
        },
      })
    },
  toggleSendButtonState:
    (value) =>
    ({ setState, getState }) => {
      setState({
        chatBox: {
          ...getState()?.chatBox,
          disableSendButton: value,
        },
      })
    },
  toggleDock:
    () =>
    ({ getState, setState }) => {
      setState({
        dockTabs: {
          ...getState()?.dockTabs,
          selected: getState().dockTabs.selected ? null : 'profile',
        },
      })
    },
  changeViewAsId:
    ({ viewAsId, headers }) =>
    ({ setState, dispatch }) => {
      setState({
        viewAsId,
        currentAgentTeams: initialState.currentAgentTeams,
      })

      dispatch(actions.fetchCurrentAgentTeams({ headers }))

      // Reset ConversationsList and ConversationsPagination
      dispatch(actions.resetConversations())
      // Fetch current conversationsList
      dispatch(actions.fetchConversations({ headers }))
    },
  updateEditTemplateState:
    (property, value) =>
    ({ setState, getState }) => {
      setState({
        editTemplate: {
          ...getState()?.editTemplate,
          [property]: value,
        },
      })
    },
  openMenu:
    (openMenu) =>
    ({ setState }) => {
      setState({
        openMenu,
      })
    },
  toggleEmojiPanel:
    () =>
    ({ getState, setState }) => {
      setState({
        showEmojiPanel: !getState()?.showEmojiPanel,
      })
    },
  changeEmojiPanel:
    (value) =>
    ({ setState }) => {
      setState({
        showEmojiPanel: value,
      })
    },
  showReAssign:
    (showReAssign) =>
    ({ setState }) =>
      setState({ showReAssign }),
  changeQuickRepliesSearch:
    ({ quickRepliesSearchText, headers, service }) =>
    async ({ dispatch, setState }) => {
      await setState({ quickRepliesSearchText })
      dispatch(actions.fetchQuickReplies({ headers, service }))
    },
  changeQuickTemplatesSearch:
    (quickTemplatesSearchText) =>
    ({ setState }) =>
      setState({ quickTemplatesSearchText, quickRepliesPagination: {} }),
  updateQuickRepliesFilter:
    ({ value, headers, service }) =>
    async ({ getState, setState, dispatch }) => {
      if (!getState().quickRepliesFilter?.includes(value)) {
        await setState({
          quickRepliesFilter: [...getState().quickRepliesFilter, value],
        })
        dispatch(actions.fetchQuickReplies({ headers, service }))
      }
    },
  removeQuickReplyFilter:
    ({ value, headers, service }) =>
    async ({ getState, setState, dispatch }) => {
      const quickRepliesFilter = getState()?.quickRepliesFilter
      if (value) {
        quickRepliesFilter.splice(quickRepliesFilter.indexOf(value), 1)
      } else {
        quickRepliesFilter.pop()
      }

      setState({
        quickRepliesFilter: [...quickRepliesFilter],
        quickRepliesPagination: {},
      })

      dispatch(actions.fetchQuickReplies({ headers, service }))
    },
  setOfflineToast:
    (chatOffline) =>
    ({ setState }) => {
      setState({
        chatOffline,
      })
    },
  setAgentSidebar:
    (showAgentSidebar) =>
    ({ setState }) =>
      setState({ showAgentSidebar }),
  setAgentsPopup:
    (showAgentsPopup) =>
    ({ setState }) =>
      setState({ showAgentsPopup }),
  fetchCurrentAgentTeams:
    ({ headers }) =>
    async ({ setState, getState }) => {
      try {
        const { viewAsId } = getState()
        if (viewAsId) {
          const resp = await fetcher(
            `${config.BASE_API}/agent/${viewAsId}/teams`,
            { headers }
          )
          const currentAgentTeams = resp.data
          setState({
            currentAgentTeams,
          })
        } else {
          setState({
            currentAgentTeams: initialState.currentAgentTeams,
          })
        }
      } catch (e) {
        logError(e, 'fetchCurrentAgentTeams')
      }
    },
  resetChatMessages:
    () =>
    ({ setState }) => {
      // Reseting chat messages should reset the associated navigation and the chat box
      // Also reset the searched message id
      setState({
        chatBox: initialState.chatBox,
        chatMessages: [], // initialState.chatMessages
        searchedMessage: initialState.searchedMessage,
        chatMessageNavigation: initialState.chatMessageNavigation,
      })
    },
  resetSelectedConversation:
    () =>
    ({ getState, setState, dispatch }) => {
      // Only reset selectedConversation if persistSelectedConversation is false
      const { persistSelectedConversation } = getState()

      const updatedState = {}
      if (persistSelectedConversation) {
        updatedState.persistSelectedConversation =
          initialState.persistSelectedConversation
      } else {
        updatedState.selectedConversation = deepClone(
          initialState.selectedConversation
        )
      }

      setState(updatedState)

      // Resetting selected conversation should reset chat messages also
      dispatch(actions.resetChatMessages())
    },
  resetSearchedMessageShow:
    () =>
    ({ setState, getState }) =>
      setState({
        searchedMessage: {
          ...getState().searchedMessage,
          show: initialState.searchedMessage.show,
        },
      }),
  setConversationTab:
    ({ conversationTab, headers }) =>
    ({ setState, dispatch }) => {
      // Clear current conversation list
      dispatch(actions.resetConversations())

      // Set the new conversation-tab
      setState({
        conversationTab,
      })

      // Fetch the conversations for current tab
      dispatch(actions.fetchConversations({ headers }))
    },
  resetConversations:
    () =>
    ({ setState }) => {
      setState({
        conversationsList: initialState.conversationsList,
        conversationsPagination: initialState.conversationsPagination,
      })
    },
  resetChatOffline:
    ({ headers }) =>
    ({ setState, dispatch }) => {
      // Clear current conversation list
      dispatch(actions.resetConversations())

      setState({ chatOffline: false })

      // Fetch the conversations for current tab
      dispatch(actions.fetchConversations({ headers }))
    },
  clearPersistConversation:
    () =>
    async ({ setState }) => {
      setState({
        persistSelectedConversation: initialState.persistSelectedConversation,
      })
    },
  resetConversationTab:
    () =>
    ({ setState }) =>
      setState({
        conversationTab: initialState.conversationTab,
      }),
}

export default actions
