import config from 'lib/config'
import { shopifyAppGrantRoutes } from 'lib/config/routes'
import trackEvent from 'lib/trackEvent'

export const cleanUpShopifyShopURL = (storeName) => {
  storeName = storeName.replace('http://', '').replace('https://', '')
  storeName = storeName.split('.myshopify.com')[0]
  return storeName
}

export const shopifyStoreMatching = ({ history, guestDomain }) => {
  // store domains is matching. so normal flow
  trackEvent(trackEvent.modules.settings, trackEvent.events.shopify_merger, {
    guestDomain,
  })
  history.push(
    `${shopifyAppGrantRoutes.SHOPIFY_GRANT_CONTINUE}?shop=${guestDomain}`
  )
}

export const shopifyGotoPricing = () => {
  window.console.log('Goto pricing for sign up')
}

export const shopifyStoreMismatching = ({
  history,
  guestDomain,
  nativeDomain,
}) => {
  // store domains mismatching
  trackEvent(
    trackEvent.modules.settings,
    trackEvent.events.shopify_merger_mismatch,
    {
      guestDomain,
      nativeDomain,
    }
  )
  history.push(shopifyAppGrantRoutes.SHOPIFY_GRANT_RESET)
}

export const shopifyNewConnection = ({
  nativeDomain,
  guestDomain,
  zokoShopifyId,
  history,
}) => {
  if (!nativeDomain && guestDomain && zokoShopifyId) {
    window.console.log(
      'check 8.5: new connection, since ecomStoreDomain is empty',
      guestDomain
    )
    alert('Connect zoko store with shopify store(zokoShopifyId)')
    // props.postStoreConnectionRequest(
    //   props.currentStore.storeId,
    //   props.zokoShopifyId,
    //   shop
    // );
    // analyticsWrapper('event', 'shopify', {
    //   'store_name': props.currentStore && props.currentStore.storeName,
    //   'store_id': props.currentStore && props.currentStore.storeId,
    //   'page': window.location.pathname,
    //   'action': 'zoko store .'
    // });
  } else {
    window.console.log(
      'check 8.8: ecomStoreDomain & currentStore is empty, so onboard or login',
      guestDomain
    )
    // analyticsWrapper('event', 'shopify', {
    //   'store_name': props.currentStore && props.currentStore.storeName,
    //   'store_id': props.currentStore && props.currentStore.storeId,
    //   'page': window.location.pathname,
    //   'action': 'not logged in to zoko, redirected to show message => Apply for an Official WhatsApp API account'
    // });
    window.globals.service.toast('Please login or sign up to continue.', {
      type: 'error',
    })
    history.push(
      `${shopifyAppGrantRoutes.SHOPIFY_GRANT_PRICING}?redirect_url=https://www.zoko.io/pricing&shop=`
    )
  }
}

export const shopifyGetInstallUrl = ({ guestDomain, redirectUrl, nonce }) =>
  `https://${guestDomain}/admin/oauth/authorize?client_id=${
    config.SHOPIFY_API_KEY
  }&scope=${
    config.SHOPIFY_ACCESS_SCOPES
  }&state=${nonce}&redirect_uri=${window.encodeURIComponent(
    `${window.location.origin}${redirectUrl}`
  )}`

export const shopifyGetGrantUrl = ({ installUrl, guestDomain }) =>
  `${shopifyAppGrantRoutes.SHOPIFY_GRANT_SCOPE}?redirect_url=${
    window.location.origin
  }${window.encodeURIComponent(installUrl)}&shop=${guestDomain}`

export const generateLongLivedToken = (exchangeToken) => {
  return new Promise((resolve) => {
    window.FB.api(
      `${config.FACEBOOK_BASE_URL}/oauth/access_token`,
      {
        grant_type: 'fb_exchange_token',
        client_id: config.FACEBOOK_APP_ID,
        client_secret: config.FACEBOOK_APP_SECRET,
        fb_exchange_token: exchangeToken,
      },
      (resp) => {
        resolve(resp?.access_token)
      }
    )
  })
}

export const generateReqPayload = ({ selectedPage, isFacebook = true }) => {
  if (isFacebook) {
    return {
      connection_name: selectedPage?.name,
      key: selectedPage?.id,
      access_token: selectedPage?.access_token,
      page_id: selectedPage?.id,
      image: selectedPage?.image,
    }
  }
  return {
    connection_name:
      selectedPage?.instagram_business_account?.name ||
      selectedPage?.instagram_business_account?.username,
    key: selectedPage?.instagram_business_account?.id,
    access_token: selectedPage.access_token,
    page_id: selectedPage?.id,
    image: selectedPage?.image,
    handle: selectedPage?.instagram_business_account?.username,
  }
}

export const connectionStatusMapper = (data = {}) => {
  return {
    isConnected: data?.active,
    connectionName: data?.name,
    connectionImg: data?.image,
    link: data?.link,
  }
}
