import { debouncer } from 'lib/debouncer'
import i18n from 'lib/i18n'

export const setResize =
  ({ authActions, isMobile }) =>
  () => {
    debouncer(() => {
      const _mobileCutOff = window
        .getComputedStyle(document.body)
        .getPropertyValue('--mobile-width')
      const mobileCutOff = Number(_mobileCutOff.replace('px', ''))
      const windowWidth = Number(window.innerWidth)
      const _isMobile = windowWidth <= mobileCutOff
      if (isMobile !== _isMobile) {
        authActions.setMobile(_isMobile)
      }
    })
  }

// activateNotifications() tries to enable notifications
// and returns true if it succeeds and false otherwise...
export const activateNotifications = () =>
  new Promise((resolve) => {
    const notificationsSupported = Boolean(
      window.BroadcastChannel && window.Notification
    )
    if (notificationsSupported) {
      const notificationPermission = window.Notification?.permission // current permission state
      const isNotificationsPermissionAsked = ['granted', 'denied'].includes(
        notificationPermission
      )
      if (!isNotificationsPermissionAsked) {
        window.globals.service.toast(i18n.t('notificationsPermission'), {
          id: 'fcm',
          button: {
            label: i18n.t('enableDisable'),
            onClick: async () => {
              // If notifications are granted for the first,
              //do a force reload for the settings to get enabled on some browsers

              await Notification.requestPermission()
              window.location.reload()
            },
          },
        })
      } else if (notificationPermission === 'granted') {
        resolve(true)
      }
      resolve(false)
    } else {
      window.globals.service.toast('notificationsError')
      resolve(false)
    }
  })
