import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import { Text } from 'common/UI/Text'
import { useOnClickOutside } from 'common/hooks/useOnClickOutside'

import { Icon, icons } from '../Icon'
import styles from './Modal.module.scss'
const ModalComponent = (props) => {
  const {
    children,
    onClose,
    showClose = true,
    headerTitle,
    headerWeight,
    direction = 'center',
    noPadding = false,
    wrapperClass,
  } = props

  const modalRef = useRef(null)
  useOnClickOutside(modalRef, () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  })

  const modalWrapperClass = classNames(styles.modalWrapper, {
    [styles[direction]]: direction,
    [styles.noPadding]: noPadding,
    [wrapperClass || '']: wrapperClass,
  })

  return (
    <>
      <motion.div
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className={styles.modalContainer}
      >
        <div ref={modalRef} className={modalWrapperClass}>
          {(Boolean(headerTitle) || showClose) && (
            <div className={styles.modalHeader}>
              {headerTitle && (
                <div className={styles.headerTitleContent}>
                  <Text fontWeight={headerWeight}>{headerTitle}</Text>
                </div>
              )}
              {showClose && (
                <button
                  data-testid="close-button"
                  className={styles.closeButton}
                  onClick={onClose}
                >
                  <Icon source={icons.xCircleLight} size="var(--p20)" />
                </button>
              )}
            </div>
          )}
          {children}
        </div>
      </motion.div>
    </>
  )
}
ModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  headerTitle: PropTypes.string,
  headerWeight: PropTypes.oneOf([300, 400, 500, 600, 700]),
  children: PropTypes.node,
  direction: PropTypes.oneOf(['center', 'upperRight', 'custom']),
  noPadding: PropTypes.bool,
  wrapperClass: PropTypes.string,
}
export const Modal = (props) => {
  const { isOpen = false } = props
  return (
    <AnimatePresence>{isOpen && <ModalComponent {...props} />}</AnimatePresence>
  )
}
Modal.propTypes = ModalComponent.propTypes
