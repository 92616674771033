import config from 'lib/config'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import { modifyInArrRange, uniqBy } from 'lib/utils/array'

import realtimeHandler from './helpers/realtimeHandler'
import initialState, { dateSortOptions } from './initialState'
import { mapPagination, mapConversations } from './mapper'

const actions = {
  fetchUnassignedConversations:
    ({ headers, nextPageUrl }) =>
    async ({ getState, setState }) => {
      setState({
        queueListLoader: true,
      })
      try {
        !nextPageUrl &&
          setState({
            queuePagination: initialState.queuePagination,
            queueList: initialState.queueList,
            lastSelectedId: initialState.lastSelectedId,
            queueOffline: false,
          })
        const endPoint =
          nextPageUrl || `${config.BASE_API}/conversation/unassigned`

        const conversationsResponse = await fetcher(endPoint, {
          headers,
          params: {
            sort: getState().dateSort,
          },
        })
        const conversations = conversationsResponse?.data?.results
        const queueList = nextPageUrl
          ? [...getState()?.queueList, ...mapConversations(conversations)]
          : mapConversations(conversations)
        setState({
          queuePagination: mapPagination(
            conversationsResponse?.data?.pagination
          ),
          queueList: uniqBy(queueList, ({ customerId }) => customerId),
        })
      } catch (error) {
        logError(error, 'fetchUnassignedConversations')
        window.globals.service.toast('queue.conversationsFetchError', {
          type: 'error',
        })
      }
      setState({
        queueListLoader: false,
      })
    },

  toggleSelectedConversation:
    ({ conversation }) =>
    ({ getState, setState }) => {
      const updatedState = {}
      const { queueList } = getState()
      const { customerId } = conversation

      const index = queueList.findIndex(
        (conv) => conv.customerId === customerId
      )
      queueList[index].selected = !conversation.selected

      const selectedIdx = queueList.findIndex((conv) => conv.selected)
      if (selectedIdx < 0 && !conversation.selected) {
        queueList.forEach((_, index) => {
          queueList[index].isPendingToShow = false
        })

        updatedState.lastSelectedId = initialState.lastSelectedId
      }

      updatedState.queueList = uniqBy(
        [...queueList],
        ({ customerId }) => customerId
      )

      if (conversation.selected) {
        updatedState.lastSelectedId = customerId
      }

      setState(updatedState)
    },

  shiftSelect:
    ({ conversation }) =>
    ({ getState, setState }) => {
      const updatedState = {}
      const { queueList, lastSelectedId, invertSelection } = getState()

      const updatedQueueList = modifyInArrRange({
        arr: queueList,
        marker1: conversation.customerId,
        marker2: lastSelectedId,
        markerSelector: 'customerId',
        modifier: (item) => (item.selected = !invertSelection),
      })

      updatedState.queueList = uniqBy(
        [...updatedQueueList],
        ({ customerId }) => customerId
      )

      setState(updatedState)
    },

  setSelectionInvert:
    (invertSelection) =>
    ({ setState, getState }) => {
      const { queueList } = getState()
      const updatedState = { invertSelection }

      const updatedQueueList = queueList.map((item) => {
        item.selected = false
        if (!invertSelection) {
          item.isPendingToShow = false
        }
        return item
      })
      updatedState.queueList = updatedQueueList

      setState(updatedState)
    },

  realtimeQueueUpdate: (realtimeEvent) => (stateObject) => {
    const { event_name: eventName, payload } = realtimeEvent

    return realtimeHandler({ eventName, payload, stateObject })
  },

  toggleDateSort:
    ({ headers }) =>
    ({ setState, getState, dispatch }) => {
      setState({
        invertSelection: initialState.invertSelection,
        dateSort:
          getState().dateSort === dateSortOptions.desc
            ? dateSortOptions.asc
            : dateSortOptions.desc,
      })

      dispatch(actions.fetchUnassignedConversations({ headers }))
    },
  updateQueueOffline:
    (queueOffline) =>
    ({ setState }) =>
      setState({ queueOffline }),

  assignSelected:
    ({ agentId, headers }) =>
    async ({ getState, dispatch }) => {
      try {
        const { queueList, invertSelection, queuePagination } = getState()
        const customers = queueList
          .filter((item) => item.selected)
          .map((item) => item.customerId)

        const { totalItems } = queuePagination
        const _selectedCount = queueList.reduce((count, elem) => {
          if (elem.selected) return count + 1
          return count
        }, 0)
        const selectedCount = invertSelection
          ? totalItems - _selectedCount
          : _selectedCount

        const resp = await fetcher(
          `${config.BASE_API}/conversation/unassigned/all`,
          {
            headers,
            body: {
              invert: invertSelection,
              customers,
              assign_to: agentId,
              assign_status: 'assigned',
            },
          }
        )

        if (resp.ok) {
          window.console.log('selectedCount', selectedCount)
          window.globals.service.toast('queue.assignSuccessNotify', {
            type: 'success',
            translateProps: { count: selectedCount },
          })
        } else {
          throw 'Failed to assign'
        }
      } catch (error) {
        logError(error, 'assignSelected')
        window.globals.service.toast('queue.assignFailed', {
          type: 'error',
        })
      } finally {
        dispatch(actions.setSelectionInvert(false))
      }
    },

  closeSelected:
    ({ agentId, headers }) =>
    async ({ getState, dispatch }) => {
      try {
        const { queueList, invertSelection, queuePagination } = getState()
        const customers = queueList
          .filter((item) => item.selected)
          .map((item) => item.customerId)

        const { totalItems } = queuePagination
        const _selectedCount = queueList.reduce((count, elem) => {
          if (elem.selected) return count + 1
          return count
        }, 0)
        const selectedCount = invertSelection
          ? totalItems - _selectedCount
          : _selectedCount

        const resp = await fetcher(
          `${config.BASE_API}/conversation/unassigned/all`,
          {
            headers,
            body: {
              invert: invertSelection,
              customers,
              assign_to: agentId,
              assign_status: 'closed',
            },
          }
        )

        if (resp.ok) {
          window.console.log('selectedCount', selectedCount)
          window.globals.service.toast('queue.closeSuccessNotify', {
            type: 'success',
            translateProps: { count: selectedCount },
          })
        } else {
          throw 'Failed to close'
        }
      } catch (error) {
        logError(error, 'closeSelected')
        window.globals.service.toast('queue.closeFailed', {
          type: 'error',
        })
      } finally {
        dispatch(actions.setSelectionInvert(false))
      }
    },
}

export default actions
