import { billingRoutes } from 'lib/config/routes'
import i18n from 'lib/i18n'

//minimum topup value allowed from zoko.
const minTopupValue = 20

//auto topup default value of "when ballance falls below"
const defaultAutoTopup = 10

//only if the subscription status of the store is in this list we should allow store to update and select the same plan.
const paymentChangeAcceptedStatusList = ['canceled', 'incomplete_expired']

//only if the subscription status of the store is in the list, the account gets blocked.
const accountBlockedStatuses = ['unpaid', 'past_due']

const invoiceStatusList = {
  open: 'open',
  paid: 'paid',
  draft: 'draft',
  refunded: 'refunded',
}

const creditOptions = [
  {
    label: '$20',
    value: minTopupValue,
  },
  {
    label: '$100',
    value: 100,
  },
  {
    label: '$200',
    value: 200,
  },
  {
    label: '$500',
    value: 500,
  },
  {
    label: '$1000',
    value: 1000,
  },
  {
    label: '$2000',
    value: 2000,
  },
  {
    label: '$3000',
    value: 3000,
  },
  {
    label: '$4000',
    value: 4000,
  },
  {
    label: '$5000',
    value: 5000,
  },
  {
    label: '$6000',
    value: 6000,
  },
]

const planOptions = [
  {
    label: 'Monthly',
    value: 'MONTH',
  },
  {
    label: 'Yearly',
    value: 'YEAR',
  },
]

const subscriptionCardStatusMapper = {
  active: 'Up to date',
  past_due: 'Past Due',
  unpaid: 'Unpaid',
  canceled: 'Cancelled',
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete / Expired',
  trialing: 'Trailing',
}

const billingExportHeaders = [
  {
    label: i18n.t('settingsPage.reportsPage.tableLabels.category'),
    key: 'category',
  },
  {
    label: i18n.t('settingsPage.reportsPage.tableLabels.totalQuantity'),
    key: 'total_quantity',
  },
  {
    label: i18n.t('settingsPage.reportsPage.tableLabels.chargeableQuantity'),
    key: 'chargeable_quantity',
  },
  {
    label: i18n.t('settingsPage.reportsPage.tableLabels.rate'),
    key: 'rate',
  },
  {
    label: i18n.t('settingsPage.reportsPage.tableLabels.charges'),
    key: 'charges',
  },
]

const billingTabs = [
  {
    label: i18n.t('settingsPage.billingPage.tabs.wallet'),
    value: billingRoutes.WALLET,
  },
  {
    label: i18n.t('settingsPage.billingPage.tabs.usageReports'),
    value: billingRoutes.USAGE_REPORTS,
  },
  {
    label: i18n.t('settingsPage.billingPage.tabs.notifications'),
    value: billingRoutes.BILLING_NOTIFICATIONS,
  },
]

export {
  minTopupValue,
  defaultAutoTopup,
  creditOptions,
  planOptions,
  subscriptionCardStatusMapper,
  billingExportHeaders,
  billingTabs,
  paymentChangeAcceptedStatusList,
  accountBlockedStatuses,
  invoiceStatusList,
}
