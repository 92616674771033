import {
  RealtimeEvents,
  RealtimeMessageEvents,
} from 'modules/Socket/realtimeEvents'

import handleAssignmentEvent from './handleAssignmentEvent'
import handleMessageDeliveryUpdate from './handleMessageDeliveryUpdate'
import handleMessageEvents from './handleMessageEvents'

const realtimeHandler = (params) => {
  const { eventName } = params
  if (Object.values(RealtimeMessageEvents).includes(eventName)) {
    return handleMessageEvents(params)
  } else if (eventName === RealtimeEvents.messageDeliveryUpdate) {
    return handleMessageDeliveryUpdate(params)
  } else if (eventName === RealtimeEvents.chatAssignment) {
    return handleAssignmentEvent(params)
  }
}

export default realtimeHandler
