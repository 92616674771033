import { icons } from 'common/UI/Icon'

export const CHANNEL_TYPES = {
  whatsapp: {
    value: 'whatsapp',
    icon: icons.whatsappLogoFill,
    label: 'WhatsApp',
    displayImg: icons.whatsapp,
    policyLink: 'https://www.whatsapp.com/legal/business-policy/?lang=en',
  },
  instagram: {
    value: 'instagram',
    icon: icons.instagram,
    label: 'Instagram',
    displayImg: icons.instagramLogo,
    policyLink:
      'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview',
  },
  facebook: {
    value: 'facebook',
    icon: icons.facebookRound,
    label: 'Facebook',
    displayImg: icons.fbLogoRound,
    policyLink:
      'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview',
  },
}

export const channelList = [
  {
    label: CHANNEL_TYPES.whatsapp.label,
    value: CHANNEL_TYPES.whatsapp.value,
  },
  {
    label: CHANNEL_TYPES.facebook.label,
    value: CHANNEL_TYPES.facebook.value,
  },
  {
    label: CHANNEL_TYPES.instagram.label,
    value: CHANNEL_TYPES.instagram.value,
  },
]
