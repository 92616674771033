import { uniqBy } from 'lib/utils/array'

import { deliveryStatusMapper } from '../../mapper'

const handleMessageDeliveryUpdate = ({ payload, stateObject }) => {
  const { setState, getState } = stateObject
  const { chatMessages } = getState()

  const updatedState = {} // This will hold the final updated state

  const messageIdx = chatMessages.findIndex(
    (_) => _.messageId === payload.message_id
  )

  if (messageIdx >= 0) {
    // if message exists in the chatMessages list, then update its status

    chatMessages[messageIdx].status =
      deliveryStatusMapper[payload.delivery_status]

    updatedState.chatMessages = uniqBy(
      chatMessages,
      ({ messageId }) => messageId
    )
  }

  setState(updatedState)
}

export default handleMessageDeliveryUpdate
