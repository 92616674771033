import { icons } from 'common/UI/Icon'

export const tagCategories = {
  system: 'system',
  shopify: 'shopify',
  zoko: 'zoko',
}
export const noTagsApplied = 'no-tags-8aec0844-b70f-11ec-b909-0242ac120002'
export const tagColorManager = (category) =>
  ({
    [tagCategories.system]: {
      color: '#000000',
      backgroundColor: '#E8E8E8',
      icon: icons.systemTagRound,
    },
    [tagCategories.shopify]: {
      color: '#05944F',
      backgroundColor: '#D8E9E1',
      icon: icons.shopifyTagRound,
    },
    [tagCategories.zoko]: {
      color: '#FF6937',
      backgroundColor: '#F7DFD7',
      icon: icons.zokoTagRound,
    },
  }[category])
