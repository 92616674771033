import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

import { useService, useToastService } from '../store'
import styles from './Toast.module.scss'
import ToastPresent from './ToastPresent'

const Toast = () => {
  const [toast] = useToastService()
  const service = useService()
  window.globals = window.globals || {}
  window.globals.service = service
  return (
    <AnimatePresence initial={false}>
      {toast && toast.length ? (
        <motion.section
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { top: 'var(--open-top)', bottom: 'var(--open-bottom)' },
            collapsed: {
              top: 'var(--collapsed-top)',
              bottom: 'var(--collapsed-bottom)',
            },
          }}
          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          className={styles.wrapper}
        >
          {toast?.map((toa) => (
            <motion.section
              key={toa.id}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto', overflow: 'initial' },
                collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <ToastPresent {...toa} />
            </motion.section>
          ))}
        </motion.section>
      ) : null}
    </AnimatePresence>
  )
}

export default Toast
