import { onIdTokenChanged } from 'firebase/auth'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ChatScreenSkeleton } from 'common/UI/LoadingSkeleton'
import routes, { shopifyAppGrantRoutes } from 'lib/config/routes'
import { deleteFCMToken, firebaseAuth, getFCMToken } from 'lib/firebase'
import trackEvent from 'lib/trackEvent'
import setTrackingProfile from 'lib/utils/setTrackingProfile'
import { getParams } from 'lib/utils/url'
import { useChatMessages, useViewAsId } from 'modules/Chat/store'
import { useQueueListData } from 'modules/Queue/store'
import { useService } from 'modules/Service/store'

import { connectSocket, disconnectSocket } from '../../Socket'
import { useAuth, useAuthHeaders, useMobile, useStoreList } from '../store'
import styles from './Authenticator.module.scss'
import CriticalAlert from './CriticalAlert'
import NoStoreFound from './NoStoreFound'
import { activateNotifications, setResize } from './helper'

let offlineTimer = null
let prevPath = null

const Authenticator = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const [userData, authActions] = useAuth()
  const [headers] = useAuthHeaders()
  const [{ currentStoreId, storeList }] = useStoreList()
  const [, chatAction] = useChatMessages()
  const [, queueAction] = useQueueListData()
  const [isMobile] = useMobile()
  const service = useService()
  const [viewAsId] = useViewAsId()

  const agentEmail = userData?.email
  const agentId = userData?.agentId

  useEffect(() => {
    if (userData) {
      setTrackingProfile({ userData })
      if (window?.globals) {
        window.globals._logData = {
          ...window?.globals?._logData,
          agent_id: userData?.agentId,
          name: userData?.name,
          z_platform: isMobile ? 'mobile' : 'desktop',
        }
      }
    }
  }, [userData, isMobile])

  useEffect(() => {
    if (agentEmail && currentStoreId)
      connectSocket({
        service,
        agentEmail,
        currentStoreId,
        socketCallbacks: {
          messageListeners: [
            (message) => chatAction.realtimeChatUpdate({ ...message, headers }),
            (message) =>
              queueAction.realtimeQueueUpdate({ ...message, headers }),
          ],
          updateMqttConnection: authActions.updateMqttConnection,
        },
      })
    return disconnectSocket
  }, [
    headers,
    agentEmail,
    currentStoreId,
    chatAction,
    queueAction,
    authActions,
    service,
  ])

  const initNotificationChannel = useCallback(async () => {
    const notificationsActivated = await activateNotifications()

    if (notificationsActivated) {
      const bifrost = window.BroadcastChannel
        ? new BroadcastChannel(
            'bifsder_flksj_falvkn_bifrost_akjdflk_jfoiwe_nvlklajs'
          )
        : null // check firebase-messaging-sw comments for more info...

      const token = await getFCMToken()
      authActions.registerFCMToken({ token })

      if (bifrost) {
        bifrost.onmessage = function (ev) {
          const eventData = ev.data
          if (eventData.readyToSend) {
            bifrost.postMessage({ readyToRecieve: true })
          } else {
            const fcmData = eventData?.FCM_MSG?.data
            if (fcmData) {
              chatAction.selectConversation({
                headers,
                customerId: fcmData.customerId,
                persistSelectedConversation: true,
              })

              if (history.location.pathname !== '/') {
                history.push(routes.CHAT)
              }
            }
          }
        }

        bifrost.postMessage({ readyToRecieve: true })
      }

      return bifrost
    }
  }, [authActions, chatAction, history, headers])

  const trackInternetStatus = useCallback(() => {
    const setOffline = () => {
      offlineTimer = window.setTimeout(() => {
        authActions.updateInternetStatus(false)
        trackEvent(
          trackEvent.modules.auth,
          trackEvent.events.internet_offline_toast,
          {}
        )
      }, 10 * 1000)
    }
    const setOnline = () => {
      window.clearTimeout(offlineTimer)
      window.globals?.service?.hideToastById('offlineMessage')
      authActions.updateInternetStatus(true)
    }
    window.addEventListener('offline', setOffline)
    window.addEventListener('online', setOnline)

    // Sleep detection
    let lastTime = new Date().getTime()
    const delta = 2000
    setInterval(function () {
      const currentTime = new Date().getTime()
      if (currentTime > lastTime + delta * 2) {
        // ignore small delays
        // Probably just woke up!
        authActions.updateInternetStatus(false)
        setOnline()
        // cost cutting
        // trackEvent(trackEvent.modules.auth, trackEvent.events.sleep_toast, {})
      }
      lastTime = currentTime
    }, delta)

    return () => {
      window.removeEventListener('offline', setOffline)
      window.removeEventListener('online', setOnline)
    }
  }, [authActions])

  useEffect(() => {
    // check for internet status
    viewAsId && trackInternetStatus()
  }, [viewAsId, trackInternetStatus])

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = function (e) {
        e = e || window.event

        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = 'Are you sure?'
        }

        // For Safari
        return 'Are you sure?'
      }
    }
  }, [isMobile])

  useEffect(() => {
    if (pathname !== prevPath) {
      window?.userpilot?.reload?.()
    }
  }, [pathname])

  useEffect(() => {
    let notificationChannel
    const unlistenFirebaseAuth = onIdTokenChanged(
      firebaseAuth,
      async (user) => {
        if (user) {
          // Logged In
          authActions.updateUserData()
          notificationChannel = await initNotificationChannel()
        } else {
          // Logged Out
          deleteFCMToken()
          authActions.clearUserData()

          if (pathname === shopifyAppGrantRoutes.SHOPIFY_GRANT_MATCHER) {
            const postLoginUri = `${
              shopifyAppGrantRoutes.SHOPIFY_GRANT_MATCHER
            }?shop=${getParams('shop')}`
            const welcomeRoute = `${routes.SHOPIFY_WELCOME}?shop=${getParams(
              'shop'
            )}&post_login_uri=${postLoginUri}`
            window.console.log('welcomeLogin', postLoginUri, welcomeRoute)
            return history.replace(welcomeRoute)
          }
          history.replace(routes.LOGIN, { redirectUri: window.location.href })
        }
      }
    )

    const unlisten = () => {
      unlistenFirebaseAuth()
      notificationChannel?.close()
    }

    return unlisten
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    agentId && chatAction.changeViewAsId({ viewAsId: agentId, headers })
  }, [agentId, chatAction, currentStoreId, headers])

  useEffect(() => {
    setResize({ authActions, isMobile })()
    window.addEventListener(
      'resize',
      setResize({ authActions, isMobile }),
      true
    )
    return () => {
      window.removeEventListener('resize', setResize, true)
    }
  }, [authActions, isMobile])
  const renderChildren = storeList?.length ? children : <NoStoreFound />
  return userData ? (
    <div className={styles.authContentWrapper}>
      <CriticalAlert />
      {renderChildren}
    </div>
  ) : (
    <ChatScreenSkeleton />
  )
}

export default Authenticator

Authenticator.propTypes = {
  children: PropTypes.node,
}
