import React from 'react'

import { getParams } from 'lib/utils/url'
import ShopifyGrant from 'modules/Settings/pages/Integrations/AddApp/Shopify/ShopifyGrant'
import ShopifyGrantPricing from 'modules/Settings/pages/Integrations/AddApp/Shopify/ShopifyGrant/ShopifyGrantPricing'

import styles from './ShopifyWelcome.module.scss'

const ShopifyWelcome = () => {
  const postLoginUri = getParams('post_login_uri')
  window.console.log('ShopifyWelcome postLoginUri', postLoginUri)
  return (
    <div className={styles.shopifyWelcomeWrapper}>
      <ShopifyGrant>
        <ShopifyGrantPricing redirectUri={postLoginUri} />
      </ShopifyGrant>
    </div>
  )
}

export default ShopifyWelcome
