/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types'
import React from 'react'

import { Button } from 'common/UI/Button'
import { Icon, icons } from 'common/UI/Icon'
import { Modal } from 'common/UI/Modal'
import i18n from 'lib/i18n'

import { usePreviewService } from '../store'
import styles from './Preview.module.scss'

const Preview = () => {
  const [preview, actions] = usePreviewService()
  const { url, options = {}, currentIndex = 0 } = preview
  const { onClose } = options

  const mediaTypeSelector = {
    [Preview.mediaTypes.image]: (
      <img
        data-testid="chat-viewer-image"
        src={url?.[currentIndex]?.url}
        className={styles.imageCover}
      />
    ),
    [Preview.mediaTypes.video]: (
      <video
        data-testid="chat-viewer-video"
        className={styles.videoCover}
        src={url?.[currentIndex]?.url}
        controls
      >
        <source src={url} type="video/mp4" />
      </video>
    ),
    [Preview.mediaTypes.audio]: (
      <div className={styles.audioWrapper}>
        <audio controls className={styles.audio}>
          <source src={url?.[currentIndex]?.url} type="audio/ogg" />
          <track default kind="captions" src={url || ''} />
          {i18n.t('audioError')}
        </audio>
      </div>
    ),
    [Preview.mediaTypes.document]: (
      <div className={styles.downloadButtonWrapper}>
        <a
          href={url?.[currentIndex]?.url}
          download
          target="_blank"
          rel="noreferrer"
          className={styles.downloadLink}
        >
          <Button size="sm">{i18n.t('downloadLink')}</Button>
        </a>
      </div>
    ),
  }

  return (
    <Modal
      noPadding
      showClose={false}
      isOpen={!!url?.length}
      onClose={() => {
        onClose && onClose()
        actions.preview(false)
      }}
    >
      <div className={styles.wrapper}>
        <button
          data-testid="close-button"
          className={styles.closeButton}
          onClick={() => {
            onClose && onClose()
            actions.preview(false)
          }}
        >
          <Icon source={icons.closeGrey} size="var(--p20)" />
        </button>
        {currentIndex !== 0 && (
          <button
            data-testid="left-button"
            className={styles.leftButton}
            onClick={() => {
              actions.changePreviewPosition(currentIndex - 1)
            }}
          >
            <Icon source={icons.leftArrow} size="var(--p20)" />
          </button>
        )}

        <div>{mediaTypeSelector?.[url?.[currentIndex]?.type]}</div>
        {currentIndex !== url?.length - 1 && (
          <button
            data-testid="right-button"
            className={styles.rightButton}
            onClick={() => {
              actions.changePreviewPosition(currentIndex + 1)
            }}
          >
            <Icon source={icons.rightArrow} size="var(--p20)" />
          </button>
        )}
      </div>
    </Modal>
  )
}

Preview.mediaTypes = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  document: 'document',
}

export default Preview

Preview.propTypes = {
  url: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video']),
    })
  ),
  mediaType: PropTypes.oneOf(Object.values(Preview.mediaTypes)),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
