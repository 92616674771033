import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Loading } from 'common/UI/Loading'
import { Text } from 'common/UI/Text'

import styles from './Widget.module.scss'

const Widget = () => {
  const location = useLocation()

  useEffect(() => {
    const redirectUrl = new URLSearchParams(location.search)?.get('wdgturl')
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }, [location.search])

  return (
    <div className={styles.widgetLoader}>
      <Text translate>pleaseWait</Text>
      <Loading />
    </div>
  )
}

export default Widget
