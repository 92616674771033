import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Button } from 'common/UI/Button'
import { Icon, icons } from 'common/UI/Icon'
import { Text } from 'common/UI/Text'

import styles from './ToastPresent.module.scss'

const ToastPresent = (props) => {
  const {
    colorScheme = 'primary',
    type = 'default',
    title,
    showLeftIcon = false,
    showCloseIcon = false,
    closeOnButtonClick = true,
    button,
    message,
    onClose,
    translateProps,
    isClickable = false,
  } = props

  const toastClass = classNames(styles.toastBase, {
    [styles[colorScheme]]: colorScheme,
    [styles[type]]: type,
    [styles.isClickable]: isClickable,
  })

  const leftIcon = type === 'error' ? 'errorCircle' : 'checkCircle'

  const buttonWrapperClass = classNames(styles.button, {
    [styles.iconButton]: !!button?.icon,
  })
  const handleButtonClick = (e) => {
    e.stopPropagation()
    button?.onClick?.(e)
    closeOnButtonClick ? onClose?.(e) : null
  }
  return (
    <div
      className={toastClass}
      data-testid="toast"
      onClick={(e) => isClickable && handleButtonClick(e)}
      role="presentation"
    >
      {showLeftIcon && (
        <div className={styles.leftIconWrapper} data-testid="leftIcon">
          <Icon size="var(--p16)" source={icons[leftIcon]} />
        </div>
      )}
      <div className={styles.toastContentWrapper}>
        <div className={styles.title}>{title}</div>
        <Text
          className={styles.body}
          translate={!message?.includes(' ')}
          translateProps={translateProps}
        >
          {message}
        </Text>
      </div>
      {button && (
        <div className={buttonWrapperClass} data-testid="button">
          <Button
            size="xs"
            colorScheme={!button?.icon && 'inverted'}
            onClick={handleButtonClick}
          >
            <Text translate={!message?.includes(' ')}>{button.label}</Text>
            {button?.icon && <Icon source={button?.icon} size="var(--p16)" />}
          </Button>
        </div>
      )}
      {!button && showCloseIcon && (
        <div
          data-testid="closeIcon"
          className={
            title ? styles.rightIconWrapperWithTitle : styles.rightIconWrapper
          }
          onClick={(e) => onClose?.(e)}
          role="presentation"
        >
          <Icon size="var(--p16)" source={icons.whiteClose} />
        </div>
      )}
    </div>
  )
}

export default ToastPresent

ToastPresent.propTypes = {
  colorScheme: PropTypes.oneOf(['primary']),
  type: PropTypes.oneOf(['default', 'success', 'error']),
  title: PropTypes.string,
  autohide: PropTypes.bool,
  showLeftIcon: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  closeOnButtonClick: PropTypes.bool,
  isClickable: PropTypes.bool,
  delay: PropTypes.number,
  button: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
  onClose: PropTypes.func,
  message: PropTypes.string.isRequired,
  translateProps: PropTypes.object,
}
