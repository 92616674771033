import config from 'lib/config'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import { CHANNEL_TYPES } from 'lib/utils/channel'

export const sendChatMessage =
  ({ headers, type, templateForm }) =>
  async ({ getState, setState }) => {
    const { chatBox, selectedConversation } = getState()
    const { message, attachments } = chatBox
    const recipient = selectedConversation?.customerId
    const channel = selectedConversation?.channel
    const isPrivate = chatBox?.isNotesMode

    try {
      setState({
        chatBox: {
          ...chatBox,
          sendChatMessageLoader: true,
        },
      })

      if (type === 'template') {
        const templateId = getState()?.chatBox?.editTemplateId
        const selectedTemplate = getState()?.chatBox?.quickTemplates?.find(
          (template) => template.id === templateId
        )

        if (selectedTemplate?.button_params_count > 0) {
          const buttonsList = selectedTemplate?.components?.find(
            (item) => item.type === 'BUTTONS'
          )

          const buttons = templateForm?.filter(
            (item) => item.type === 'BUTTONS'
          )

          let formButtonLength = selectedTemplate?.button_params_count

          let buttonLength = buttons?.length || 0

          while (formButtonLength !== buttonLength) {
            const button = buttonsList?.buttons?.[buttonLength]

            templateForm.push({
              position: templateForm?.length,
              type: 'BUTTONS',
              value: button?.label,
            })
            buttonLength++
          }
        }

        const messageType = selectedTemplate.type
        const header = templateForm.find((item) => item.type === 'HEADER')
        const templateArgs = []
        templateForm?.forEach((item) => {
          templateArgs.push(item.value)
        })
        const templateLanguage = selectedTemplate?.language_code
        const templateName = selectedTemplate?.name

        const payload = {
          recipient,
          type: messageType,
          caption: header?.caption || header?.filename,
          private: isPrivate,
          template_args: templateArgs,
          template_id: templateName,
          template_language: templateLanguage,
          channel,
        }

        sendMessage(headers, payload)

        setState({
          chatBox: {
            ...getState().chatBox,
            message: '',
            attachments: [],
            sendChatMessageLoader: false,
            editTemplateId: null,
            showEditTemplateModal: false,
          },
          templateForm: [],
        })
      } else {
        if (attachments.length) {
          const documentTypes = ['text', 'application']
          attachments.forEach(async (attachment, index) => {
            const formattedMessage = attachment.url
            const rawType = attachment.type.split('/')[0]
            const messageType = documentTypes.includes(rawType)
              ? 'document'
              : rawType
            const messageCaption =
              messageType === 'document'
                ? attachment.name
                : index === 0
                ? message
                : ''

            const payload = {
              recipient,
              type: messageType,
              message: formattedMessage,
              private: isPrivate,
              channel,
            }

            if (
              channel === CHANNEL_TYPES.whatsapp.value ||
              messageType !== 'document'
            ) {
              payload.caption = messageCaption
            }

            sendMessage(headers, payload)
          })
        } else {
          const payload = {
            recipient,
            type: 'text',
            message,
            caption: '',
            private: isPrivate,
            channel,
          }
          await sendMessage(headers, payload)
        }

        setState({
          chatBox: {
            ...getState().chatBox,
            message: '',
            attachments: [],
            sendChatMessageLoader: false,
          },
        })
      }
    } catch (error) {
      logError(error, 'sendChatMessage')

      setState({
        chatBox: {
          ...getState().chatBox,
          sendChatMessageLoader: false,
        },
      })
    }
  }

export const sendMessage = async (headers, payload) =>
  await fetcher(`${config.BASE_API}/message`, {
    body: payload,
    headers,
  })
