import React from 'react'

import { Button } from 'common/UI/Button'
import { Modal } from 'common/UI/Modal'
import { Text } from 'common/UI/Text'

import { useConfitmation } from '../store'
import styles from './ServiceConfirmation.module.scss'

const ServiceConfirmation = () => {
  const [{ openConfirmation, confirmationLoader, confirmation }, actions] =
    useConfitmation()
  const {
    message,
    cancelText,
    submitText,
    colorScheme,
    desc = '',
  } = confirmation

  return (
    <Modal isOpen={openConfirmation} showClose={false}>
      <div className={styles.serviceConfirmationWrapper}>
        <Text className={styles.text} translate fontWeight={500}>
          {message}
        </Text>
        {desc && (
          <Text className={styles.desc} translate>
            {desc}
          </Text>
        )}
        <div className={styles.footer}>
          <Button
            className={styles.cancelBtn}
            size="sm"
            colorScheme="secondary"
            variant="outline"
            onClick={actions.resetConfirmation}
          >
            {cancelText}
          </Button>
          <Button
            className={styles.exportBtn}
            size="sm"
            colorScheme={colorScheme}
            onClick={async () => {
              await actions.updateConfirmationLoader(true)
              await window?.globals?.onConfirm?.()
              actions.updateConfirmationLoader(false)
              actions.resetConfirmation()
            }}
            isDisabled={confirmationLoader}
            loading={confirmationLoader}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ServiceConfirmation
