import { conversationTabs } from '../constants'

export default {
  loadingConversations: true,
  conversationsPagination: {},
  conversationsList: [],
  chatMessages: [],
  chatBox: {
    isWritting: false,
    isNotesMode: false,
    isAudioMode: false,
    disableSendButton: false,
    count: 0,
    attachments: [],
    selectedQuickOption: 'quickReplies',
    quickTemplates: null,
    showEditTemplateModal: false,
    editTemplateId: null,
    message: '',
  },
  quickReplies: [],
  quickRepliesPagination: {},
  quickRepliesFilter: [],
  quickRepliesLoader: false,
  showQuickOptions: false,
  chatMessageNavigation: {
    next: null,
    prev: null,
  },
  quickRepliesSearchText: '',
  quickTemplatesSearchText: '',
  selectedConversation: {},
  persistSelectedConversation: false, // Setting this value to true will avoid resetting the selected conversation (in resetSelectedConversation action) at least once
  loadingSelectedConversationData: false,
  conversationSearch: '',
  conversationSearchResults: [],
  contactSearchResults: [],
  conversationSearchResultsPagination: {},
  manageChatLoader: false,
  dockTabs: {
    selected: null,
  },
  viewAsId: null,
  currentAgentTeams: null,
  openMenu: false,
  showEmojiPanel: false,
  showReAssign: false,
  searchedMessage: {
    id: null,
    timestamp: null,
    show: false, // Setting this to true, scroll the searched message into view
  },
  chatOffline: false,
  showAgentSidebar: false,
  showAgentsPopup: false,
  prevLastMessageId: null,
  conversationTab: conversationTabs.all.value,
  totalUnread: null,
  totalUnresponded: null,
  requestPermissionLoader: false,
}
