import i18n from 'lib/i18n'

export const OPT_OUT_LINK = 'OPT_OUT_LINK'

export const templateButtonCategories = {
  CTA: 'cta',
  QR: 'qr',
}

export const templateButtonTypes = {
  QUICK_REPLY: 'QUICK_REPLY',
  PHONE: 'PHONE_NUMBER',
  OPT_OUT_URL: 'OPT_OUT_URL',
  URL: 'URL',
  QUICK_REPLY_OPT_OUT: 'QUICK_REPLY_OPT_OUT',
}

export const ctaButtonActionTypes = {
  PHONE: 'phone',
  DYNAMIC_LINK: 'dynamic_link',
  DYNAMIC_LINK_EXT: 'dynamic_link_ext',
  OPT_OUT_LINK: 'opt_out_link',
  STATIC_LINK: 'static_link',
}

export const qrButtonActionTypes = {
  QUICK_REPLY: 'quick_reply',
  QUICK_REPLY_OPT_OUT: 'quick_reply_opt_out',
}

export const ctaButtonActionTypesOptions = [
  {
    label: i18n.t('settingsPage.templates.buttonActions.phone'),
    value: ctaButtonActionTypes.PHONE,
  },
  {
    label: i18n.t('settingsPage.templates.buttonActions.dynamicLink'),
    value: ctaButtonActionTypes.DYNAMIC_LINK,
  },
  {
    label: i18n.t('settingsPage.templates.buttonActions.dynamicLinkExt'),
    value: ctaButtonActionTypes.DYNAMIC_LINK_EXT,
  },
  {
    label: i18n.t('settingsPage.templates.buttonActions.optOutLink'),
    value: ctaButtonActionTypes.OPT_OUT_LINK,
  },
  {
    label: i18n.t('settingsPage.templates.buttonActions.static'),
    value: ctaButtonActionTypes.STATIC_LINK,
  },
]

export const templateButtonCategoriesOptions = [
  {
    label: i18n.t('settingsPage.templates.buttonsNo'),
    value: '',
  },
  {
    label: i18n.t('settingsPage.templates.buttonsCTA'),
    value: templateButtonCategories?.CTA,
  },
  {
    label: i18n.t('settingsPage.templates.buttonsQR'),
    value: templateButtonCategories?.QR,
  },
]

export const qrButtonActionTypesOptions = [
  {
    label: i18n.t('settingsPage.templates.buttonActions.qrButton'),
    value: qrButtonActionTypes.QUICK_REPLY,
  },
  {
    label: i18n.t('settingsPage.templates.buttonActions.qrOptOut'),
    value: qrButtonActionTypes.QUICK_REPLY_OPT_OUT,
  },
]
