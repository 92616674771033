import { createStore, createHook, defaults } from 'react-sweet-state'

import actions from './actions'
import initialState from './initialState'

defaults.devtools = true
const Store = createStore({
  name: 'ZOKO-queue-' + window.location.origin,
  initialState,
  actions,
})

export const useQueueListData = createHook(Store, {
  selector: ({
    queueList,
    queuePagination,
    queueListLoader,
    dateSort,
    invertSelection,
  }) => {
    const queueData = {
      queueList,
      listLength: queueList?.length,
      pagination: queuePagination,
      queueListLoader,
      dateSort,
      invertSelection,
    }
    return queueData
  },
})

export const useQueueOffline = createHook(Store, {
  selector: (state) => state.queueOffline,
})
