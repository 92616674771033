import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { FooterHelp, Layout } from '@shopify/polaris'
import PropTypes from 'prop-types'
import React from 'react'

import routes from 'lib/config/routes'
import i18n from 'lib/i18n'
import { inIframe } from 'lib/utils/url'

import ShopifyRedirection from '../ShopifyRedirection'
import styles from './ShopifyGrantPricing.module.scss'

const ShopifyGrantPricing = ({ redirectUri }) => {
  window.console.log('ShopifyGrantPricing', redirectUri, !inIframe())
  const redirect = (redirectURL) => {
    if (!inIframe()) {
      window.location.href = redirectURL
      return
    }
    app.dispatch(
      Redirect.toRemote({
        url: redirectURL,
        externalLink: true,
      })
    )
  }
  const app = useAppBridge()
  const body =
    'Hi, I just downloaded your Shopify app. Please help me connect my official WhatsApp API account :) '
  const loginRoute = `${window.location.origin}${
    routes.LOGIN
  }?redirect_uri=${window.encodeURIComponent(redirectUri)}`
  return (
    <div className={styles.shopifyGrantPricingWrapper}>
      <Layout>
        <Layout.Section>
          <ShopifyRedirection
            title={i18n.t('shopify.pricing.title')}
            description={
              <div className={styles.list}>
                Please email us at{' '}
                <a href rel="noreferrer">
                  contact@zoko.io
                </a>{' '}
                or WhatsApp us by clicking the button below to connect your
                account.
                <br />
                <br />
                Approval guaranteed or your money back!
              </div>
            }
            redirectURL={`https://wa.me/917306017571?text=${body}`}
            externalLink
            actionText="Whatsapp Now"
          />
          <FooterHelp>
            Already a ZOKO user{' '}
            <a
              href={loginRoute}
              onClick={(e) => {
                e.preventDefault()
                redirect(loginRoute)
              }}
            >
              login here
            </a>
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </div>
  )
}

export default ShopifyGrantPricing

ShopifyGrantPricing.propTypes = {
  redirectUri: PropTypes.string,
}
