import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Text } from 'common/UI/Text'
import { billingRoutes } from 'lib/config/routes'
import { accountBlockedStatuses } from 'modules/Settings/pages/Billing/constants'

import { useAuthHeaders, useCurrentStore, useHostInfo } from '../../store'
import styles from './CriticalAlert.module.scss'

const CriticalAlert = () => {
  const [{ currentStore, subscriptionPlan }, actions] = useCurrentStore()
  const [hostInfo] = useHostInfo()
  const [headers] = useAuthHeaders()
  const history = useHistory()

  const isStoreBlocked = currentStore?.blocked

  const isSubscriptionOverdue = isStoreBlocked
    ? false
    : accountBlockedStatuses.includes(subscriptionPlan?.payment_status) // isStoreBlocked has the highest priority

  useEffect(() => {
    actions.getSubscription({ headers })
  }, [actions, headers, currentStore])

  return (
    <>
      {(isStoreBlocked || isSubscriptionOverdue) && hostInfo?.showBilling ? (
        <div
          className={classNames(styles.criticalAlertWrapper, {
            [styles.warning]: isSubscriptionOverdue,
          })}
        >
          <div className={styles.content}>
            {
              <Text
                className={styles.text}
                translate
                fontWeight={500}
                translateProps={
                  isSubscriptionOverdue
                    ? {}
                    : { blockReason: currentStore?.block_reason }
                }
              >
                {isSubscriptionOverdue
                  ? `subscriptionOverdue`
                  : `accountBlocked`}
              </Text>
            }
            <Text
              onClick={() => history.push(billingRoutes.ADD_CREDITS)}
              translate
              className={styles.btn}
              fontWeight={600}
            >
              addFunds
            </Text>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CriticalAlert
