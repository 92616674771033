import config from 'lib/config'
import { fetcher } from 'lib/fetcher'

export const manageChatApi = async (headers, payload) => {
  const { customerId, body } = payload
  return await fetcher(`${config.BASE_API}/conversation/${customerId}`, {
    headers,
    body,
  })
}
