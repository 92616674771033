import i18n from 'lib/i18n'

const quickReplyFilterTypes = [
  { label: i18n.t('chatQuickActions.text'), value: 'text' },
  { label: i18n.t('chatQuickActions.image'), value: 'image' },
  { label: i18n.t('chatQuickActions.video'), value: 'video' },
  { label: i18n.t('chatQuickActions.document'), value: 'document' },
]

export const conversationTabs = {
  all: {
    label: i18n.t('allChats'),
    value: '',
  },
  unread: {
    label: i18n.t('unreadChats'),
    value: 'unread',
  },
  unreplied: {
    label: i18n.t('unrepliedChats'),
    value: 'unreplied',
  },
}

export { quickReplyFilterTypes }
