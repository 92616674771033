import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Icon, icons } from 'common/UI/Icon'

import styles from './Loading.module.scss'

export const Loading = (props) => {
  const { variant = 'dots', size = 'sm', invert, ...rest } = props

  const dotClass = classNames(styles.dot, {
    [styles[size]]: size,
    [styles.invert]: invert,
  })

  const circleLoadingSize = size === 'sm' ? '1rem' : '2rem'

  return (
    <div className={styles.loadingWrapper} {...rest}>
      {variant === 'dots' && (
        <ul className={styles.dotsList}>
          <li data-testid="dot" className={dotClass} />
          <li data-testid="dot" className={dotClass} />
          <li data-testid="dot" className={dotClass} />
        </ul>
      )}
      {variant === 'circle' && (
        <div data-testid="circle" className={styles.circleContent}>
          <Icon source={icons.circleLoading} size={circleLoadingSize} />
        </div>
      )}
    </div>
  )
}

Loading.size = { sm: 'sm', md: 'md', xs: 'xs' }
Loading.variant = { dots: 'dots', circle: 'circle' }

Loading.propTypes = {
  size: PropTypes.oneOf(Object.values(Loading.size)),
  variant: PropTypes.oneOf(Object.values(Loading.variant)),
  invert: PropTypes.bool,
}
