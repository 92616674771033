import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { EmptyState } from '@shopify/polaris'
import { PropTypes } from 'prop-types'
import React, { useEffect } from 'react'

import { getParams, inIframe } from 'lib/utils/url'

import styles from './ShopifyRedirection.module.scss'

const ShopifyRedirection = ({
  title,
  description,
  instantRedirect,
  redirectURL,
  actionText = 'Continue',
  secondaryText,
  secondaryLink,
  externalLink,
}) => {
  const app = useAppBridge()

  window.console.log('ShopifyRedirection', {
    instantRedirect,
    redirectURL,
    externalLink,
  })

  useEffect(() => {
    if (instantRedirect && redirectURL) {
      if (!inIframe()) {
        window.location.href = redirectURL
        window.console.log('!inIframe()')
        return
      }
      window.console.log('dispatch')
      app.dispatch(
        Redirect.toRemote({
          url: redirectURL,
          externalLink,
        })
      )
    }
  }, [app, instantRedirect, redirectURL, externalLink])

  const onAction = {
    content: actionText,
    onAction: () => {
      window.console.log('onAction', redirectURL, getParams('shop'))
      if (!inIframe()) {
        window.location.href = redirectURL
        return
      }
      app.dispatch(
        Redirect.toRemote({
          url: redirectURL,
          externalLink,
        })
      )
    },
  }

  return (
    <div className={styles.shopifyRedirectionWrapper}>
      <EmptyState
        heading={<span className={styles.title}>{title}</span>}
        action={instantRedirect && !inIframe() ? null : onAction}
        secondaryAction={
          secondaryText
            ? {
                content: secondaryText,
                url: secondaryLink,
              }
            : null
        }
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <span>{description}</span>
      </EmptyState>
    </div>
  )
}

export default ShopifyRedirection

ShopifyRedirection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  redirectURL: PropTypes.string,
  loader: PropTypes.bool,
  instantRedirect: PropTypes.bool,
  actionText: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryLink: PropTypes.string,
  externalLink: PropTypes.bool,
}
