export const mapPagination = ({
  current_page_no: currentPageNo,
  page_size: pageSize,
  total_items: totalItems,
  total_pages: totalPages,
  next_page_url: nextPageUrl,
}) => {
  return {
    currentPageNo,
    pageSize,
    totalItems,
    totalPages,
    nextPageUrl,
  }
}

export const mapConversations = (conversations) =>
  conversations.map(
    ({
      id: customerId,
      name: name,
      image_url: dp,
      last_message_at: lastMessageAt,
      last_message: lastMessage,
      last_incoming_message_at: lastIncomingMessageAt,
      last_message_type: lastMessageType,
      id_on_platform: platformId,
      sentiment,
      platform,
    }) => {
      const _sentiment = generateSentimentEmoji(sentiment)
      return {
        customerId,
        name,
        dp,
        lastMessageAt,
        lastMessage,
        lastIncomingMessageAt,
        lastMessageType,
        isPendingToShow: false,
        platformId,
        sentiment: _sentiment,
        channel: platform,
      }
    }
  )

export const generateSentimentEmoji = (sentiment) => {
  switch (sentiment) {
    case 'very_low':
      return '🤬'
    case 'low':
      return '😡'
    case 'medium':
      return '😕'
    case 'high':
      return '😁'
    default:
      return '-'
  }
}
