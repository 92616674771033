import mqtt from 'mqtt'
import { v4 as uuidv4 } from 'uuid'

import Sounds from 'common/assets/sounds'
import config from 'lib/config'
import { getAuthToken } from 'lib/firebase'
import logError from 'lib/logError'
import { throttler } from 'lib/throttler'

let mqttConnection = null // socket connection variable
let currentAuthToken = null // local auth token variable for transformWsUrl

const setCurrentAuthToken = async () => {
  currentAuthToken = await getAuthToken()
  window.console.log(
    'MQTT: setCurrentAuthToken: length',
    currentAuthToken?.length
  )
}

const transformWsUrl = (url, _, client) => {
  // Set latest auth token on reconnect
  window.console.log('MQTT: transformWsUrl')
  client.options.username = currentAuthToken
  return url
}

const connectSocket = async ({
  service,
  agentEmail,
  currentStoreId,
  socketCallbacks,
}) => {
  await setCurrentAuthToken()
  mqttConnection = mqtt.connect(config.MQTT_URL, {
    clientId: `${agentEmail}-${uuidv4()}`,
    transformWsUrl: transformWsUrl,
  })

  mqttConnection.on('connect', () => {
    window.console.log('MQTT: Socket Connected')
    subscribeTopic(currentStoreId)
    socketCallbacks.updateMqttConnection(true)
  })

  mqttConnection.on('error', async (err) => {
    window.console.log('MQTT: Connection error: ', err)
    await setCurrentAuthToken()
    // mqttConnection.end()
  })

  mqttConnection.on('reconnect', () => {
    window.console.log('MQTT: Socket reconnecting')
  })

  mqttConnection.on('disconnect', (packet) => {
    window.console.log('MQTT: Socket disconnect => Packet', packet)
  })

  mqttConnection.on('offline', () => {
    window.console.log('MQTT: Socket offline')
    socketCallbacks.updateMqttConnection(false)
  })

  mqttConnection.on('end', () => {
    window.console.log('MQTT: Socket end')
  })

  mqttConnection.on('message', (topic, message) => {
    try {
      message = JSON.parse(message.toString())
      window.console.log('recieved message', topic, message)
      socketCallbacks.messageListeners.forEach((action) => {
        const postAction = action(message) // postActions is an object returned with configs for further actions

        const playNotification = postAction?.playNotification
        if (playNotification) {
          throttler(() => service.playAudio(Sounds.notificationSound), 5000)
        }
      })
    } catch (error) {
      logError(error, 'mqttConnection:onMessage')
    }
  })
}

const disconnectSocket = () => {
  window.console.log('MQTT: Disconnecting Socket:', mqttConnection)
  if (mqttConnection) {
    mqttConnection.end(() => {
      window.console.log('MQTT: Disconnected Socket:', mqttConnection)
    })
  }
}

const subscribeTopic = (topic) => {
  window.console.log('MQTT: Subscribing to:', topic)
  if (mqttConnection) {
    mqttConnection.subscribe(topic, { qos: 0 }, (error) => {
      if (error) {
        window.console.log('MQTT: Subscribe to topics error', error)
        return
      }
      window.console.log('MQTT: Subscribed to:', topic)
    })
  }
}

const unSubscribeTopic = (topic) => {
  window.console.log('MQTT: Unsubscribing from:', topic)
  if (mqttConnection) {
    mqttConnection?.unsubscribe(topic, (error) => {
      if (error) {
        window.console.log('MQTT: Unsubscribe error', error)
        return
      }
      window.console.log('MQTT: Unsubscribed from:', topic)
    })
  }
}

const isConnected = () => mqttConnection?.connected

export {
  connectSocket,
  disconnectSocket,
  subscribeTopic,
  unSubscribeTopic,
  isConnected,
}
